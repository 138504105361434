import { TextField } from "@material-ui/core";
import { useEffect, useRef } from "react";

export function DirAutoTextInput(props) {
  const ref = useRef();

  const checkDir = (e) => {
    updateDir(e.target.value);
  };

  const updateDir = (value) => {
    const rtlRegex = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
    const direction = rtlRegex.test(value) ? "rtl" : "ltr";
    ref.current.style.direction = direction;
  };

  useEffect(() => updateDir(props.value || props.defaultValue), []);

  return <TextField {...props} ref={ref} onChange={checkDir} />;
}
