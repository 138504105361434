import React, {
  Suspense,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import SettingsLayout, { settingsList } from "../../../Layouts/SettingsLayout";
import ImportExport from "../ImportExport";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getTooltips,
  getKiosk,
  getSettings,
  updateSettings,
} from "../../../api";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router";
import { getFormPayload } from "../../../utils";
import { Link } from "react-router-dom";
import useSaveAcross from "../../../Components/useSaveAcross";
import { RestartAlt } from "@material-ui/icons";
import { RouterPrompt } from "../../../Components/RouterPrompt";
import KioskType from "../../../Components/KioskType";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "auto 0 !important",
    display: "flex",
    "& img": {
      marginLeft: "20px !important",
      display: "block",
    },
  },
  saveButton: {
    marginLeft: "auto !important",
  },
  clearButton: {
    marginLeft: 8,
    alignSelf: "flex-start",
    flexShrink: 0,
  },
  progress: {
    margin: "100px auto !important",
  },
  breadcrumbs: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default function Index({ smartType = null }) {
  const smartLink = smartType ? "/smart" : "";

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { siteId, kioskId, tab } = useParams();
  const history = useHistory();

  const { data: tooltips } = useQuery("tooltips", getTooltips());

  const [dirty, setDirty] = useState(false);

  const onDirty = () => {
    setDirty(true);
  };

  useEffect(() => {
    if (!tab)
      history.push(
        `/sites/${siteId}/kiosks/${kioskId}${smartLink}/serveIT/settings/lms`
      );
  }, [tab, history, siteId, kioskId, smartLink]);

  const tabTitle = useMemo(
    () => tab && settingsList.serveIT.find((e) => e.url === tab).title,
    [tab]
  );

  const tabModule = useMemo(
    () => tab && settingsList.serveIT.find((e) => e.url === tab).module,
    [tab]
  );

  const SettingsComponent = React.lazy(
    () => tab && import(`./Tabs/${tabModule}`)
  );

  const [kiosk, setkiosk] = useState();
  useQuery(["sites", siteId, "kiosks", kioskId], getKiosk(siteId, kioskId), {
    onSuccess: (kiosk) => setkiosk(kiosk),
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const [settings, setSettings] = useState({});

  const { isLoading } = useQuery(
    ["settings", kioskId, tab],
    getSettings(siteId, kioskId, tab, smartType),
    {
      enabled: !!tab,
      onSuccess: (settings) => {
        setSettings(settings);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const container = React.useRef(null);

  const {
    mutate: doUpdateSettings,
    isLoading: isUpdateLoading,
    error,
  } = useMutation(updateSettings, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries(["settings", kioskId, tab]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const onSave = useCallback(
    (saveOption, selectedIds) => {
      doUpdateSettings({
        siteId,
        kioskId,
        tab,
        saveOption,
        selectedIds,
        smartType,
        settings,
      });
      setDirty(false);
    },
    [doUpdateSettings, siteId, kioskId, tab, settings]
  );

  const [openModal, modal] = useSaveAcross({
    onSave,
    forcedId: `${siteId}-${kioskId}`,
    type: "serveIT",
  });

  const handleSubmit = useCallback(
    (form, isPayload = false) => {
      let settings = {};
      if (isPayload) settings = form;
      else settings = getFormPayload(form);
      setSettings(settings);
      openModal();
    },
    [setSettings, openModal]
  );

  const onClear = useCallback(() => setSettings({}), [setSettings]);

  const validationErrors = error && error.errors;

  return (
    <SettingsLayout smart={!!smartType} type="serveIT">
      <RouterPrompt
        when={dirty}
        body={
          <>
            Your changes have not been saved.
            <br />
            Are you sure you wish to leave this page?
          </>
        }
        title="Unsaved changes"
      />
      <ImportExport
        metadata={{ siteId, kioskId }}
        cacheKey={["settings", kioskId]}
        type="settings"
      />
      <Box display="flex">
        {kiosk && (
          <Typography variant="h5" className={classes.title}>
            {kiosk.site.name} - {kiosk.name} <KioskType type={kiosk.type_str} />
          </Typography>
        )}
        <div className={classes.saveButton} ref={container} />
        <Button
          variant="outlined"
          className={classes.clearButton}
          onClick={onClear}
          startIcon={<RestartAlt />}
        >
          Clear
        </Button>
      </Box>
      {modal}
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <Link
          color="inherit"
          to={`/sites/${siteId}/kiosks/${kioskId}${smartLink}/serveIT/settings`}
        >
          Settings
        </Link>
        <Typography color="textPrimary">{tabTitle}</Typography>
      </Breadcrumbs>
      {tab && (
        <Suspense
          fallback={
            <Box display="flex">
              <CircularProgress className={classes.progress} />
            </Box>
          }
        >
          {isLoading ? (
            <Box display="flex">
              <CircularProgress className={classes.progress} />
            </Box>
          ) : (
            <SettingsComponent
              isUpdateLoading={isUpdateLoading}
              onDirty={onDirty}
              handleSubmit={handleSubmit}
              container={container}
              tooltips={tooltips?.[tab]}
              settings={settings}
              validationErrors={validationErrors}
              smart={!!smartType}
            />
          )}
        </Suspense>
      )}
    </SettingsLayout>
  );
}
