import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete, Edit, Add, Dock } from "@material-ui/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteSite, getSites } from "../../api";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(4),
  },
  allButton: {
    marginLeft: "auto !important",
    textDecoration: "none",
  },
  createButton: {
    marginLeft: theme.spacing(4),
  },
  progress: {
    margin: "100px auto !important",
  },
}));

export default function Index() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { isLoading, data: sites } = useQuery(["sites"], getSites(), {
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });

  const { mutate: doDeleteSite } = useMutation(deleteSite, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries(["sites"]);
    },
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });

  const confirm = useConfirm();
  const onDelete = (id) => async () => {
    try {
      await confirm({
        title: "Delete site",
        description:
          "Are you sure you want to delete this site, this action will delete all settings, stats and data for this site and underlying kiosks, this is permanent",
        confirmationText: "delete",
      });
      doDeleteSite(id);
    } catch (e) {}
  };

  return (
    <Layout>
      <Box display="flex">
        <Typography variant="h5">Sites Management</Typography>
        <Link to="/kiosks" className={classes.allButton}>
          <Button variant="outlined" color="primary">
            View All kiosks
          </Button>
        </Link>
        <Link to="/sites/create" className={classes.createButton}>
          <Button variant="contained" color="primary" startIcon={<Add />}>
            Create
          </Button>
        </Link>
      </Box>

      {isLoading ? (
        <Box display="flex">
          <CircularProgress className={classes.progress} />
        </Box>
      ) : (
        <TableContainer component={Paper} className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sites && sites.length > 0 ? (
                sites.map((site) => (
                  <TableRow key={site.id}>
                    <TableCell component="th" scope="row">
                      {site.name}
                    </TableCell>
                    <TableCell align="left">
                      <Link to={`/sites/${site.id}/kiosks`}>
                        <IconButton aria-label="kiosks" title="Kiosks">
                          <Dock />
                        </IconButton>
                      </Link>
                      <Link to={`/sites/${site.id}/edit`}>
                        <IconButton aria-label="edit" title="Edit">
                          <Edit />
                        </IconButton>
                      </Link>
                      <IconButton
                        aria-label="delete"
                        title="Delete"
                        color="secondary"
                        onClick={onDelete(site.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No sites found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Layout>
  );
}
