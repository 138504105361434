import { makeStyles } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { TreeItem, TreeView } from "@material-ui/lab";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getSitesTree } from "../api";

const useStyles = makeStyles((theme) => ({
  treeItem: {
    backgroundColor: "transparent !important",
    height: 40,
  },
}));

export function KioskSelector({ onChange }) {
  const classes = useStyles();

  const { data: sitesTree } = useQuery(["sites", "tree"], getSitesTree(), {
    staleTime: 180 * 1000,
  });

  const [selected, setSelected] = useState(new Set());

  const handleTreeSelect = (event, nodeId) => {
    let newSelected = new Set([...selected]);
    if (nodeId.includes("-")) {
      if (newSelected.has(nodeId)) {
        newSelected.delete(nodeId);
        const libId = nodeId.split("-")[0];
        newSelected.delete(libId);
      } else newSelected.add(nodeId);
    } else {
      if (newSelected.has(nodeId)) {
        newSelected.delete(nodeId);
        const ids =
          sitesTree
            .find((site) => `${site.id}` === nodeId)
            ?.kiosks?.map((kiosk) => `${kiosk.site_id}-${kiosk.id}`) || [];
        for (let id of ids) newSelected.delete(id);
      } else {
        newSelected = new Set([
          ...newSelected,
          nodeId,
          ...sitesTree
            .find((site) => `${site.id}` === nodeId)
            ?.kiosks?.map((kiosk) => `${kiosk.site_id}-${kiosk.id}`),
        ]);
      }
    }
    setSelected(newSelected);
  };

  const selectedIds = useMemo(
    () =>
      [...selected].filter((e) => e.includes("-")).map((e) => e.split("-")[1]),
    [selected]
  );

  useEffect(() => {
    onChange(selectedIds);
  }, [onChange, selectedIds]);

  return sitesTree ? (
    <TreeView
      expanded={sitesTree.map((site) => `${site.id}`)}
      onNodeSelect={handleTreeSelect}
      selected={[...selected]}
    >
      {sitesTree.map((site) => (
        <TreeItem
          classes={{
            content: classes.treeItem,
          }}
          key={site.id}
          nodeId={`${site.id}`}
          label={site.name}
          icon={
            selected.has(`${site.id}`) ? (
              <CheckBox fontSize="small" color="primary" />
            ) : (
              <CheckBoxOutlineBlank fontSize="small" color="primary" />
            )
          }
        >
          {site.kiosks &&
            site.kiosks.map((kiosk) => (
              <TreeItem
                classes={{
                  content: classes.treeItem,
                }}
                key={kiosk.id}
                nodeId={`${site.id}-${kiosk.id}`}
                label={kiosk.name}
                icon={
                  selected.has(`${site.id}-${kiosk.id}`) ? (
                    <CheckBox fontSize="small" color="primary" />
                  ) : (
                    <CheckBoxOutlineBlank fontSize="small" color="primary" />
                  )
                }
              />
            ))}
        </TreeItem>
      ))}
    </TreeView>
  ) : null;
}
