import { makeStyles } from "@material-ui/core/styles";
import Layout from "../Layouts/AppLayout";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getEmailServerSettings, updateEmailServerSettings } from "../api";
import { useSnackbar } from "notistack";
import { Clear, MarkEmailRead, Save } from "@material-ui/icons";
import { useCallback } from "react";
import { getFormPayload } from "../utils";
import { Input, InputNumber, InputPassword, Select } from "./Settings/Inputs";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(4),
  },
  createButton: {
    marginLeft: "auto !important",
  },
  progress: {
    margin: "100px auto !important",
  },
  centerContainer: {
    margin: "0 auto",
  },
}));

export default function NotificationSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    isLoading,
    data: emailServerSettings,
    error,
  } = useQuery(["smtp"], getEmailServerSettings(), {
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });

  const { mutate: doSave, isLoading: isUpdateLoading } = useMutation(
    updateEmailServerSettings,
    {
      onSuccess: ({ message }) => {
        enqueueSnackbar(message, { variant: "success" });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const settings = getFormPayload(e.target, e.nativeEvent);
      doSave(settings);
    },
    [doSave]
  );

  const onReset = () => {
    queryClient.setQueryData(["smtp"], (old) => {
      return {
        encryption: null,
        fromEmail: null,
        fromName: null,
        host: null,
        password: null,
        port: null,
        username: null,
      };
    });
  };

  const validationErrors = error && error.errors;

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9} lg={6} className={classes.centerContainer}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box display="flex">
              <Typography variant="h5">Email Server</Typography>
              <Button
                style={{ marginLeft: "auto" }}
                type="submit"
                name="action"
                value="test"
                variant="contained"
                color="inherit"
                startIcon={<MarkEmailRead />}
                disabled={isUpdateLoading}
              >
                Test
              </Button>
              <Button
                style={{ marginLeft: 8 }}
                type="reset"
                variant="contained"
                color="inherit"
                startIcon={<Clear />}
                disabled={isUpdateLoading}
                onClick={onReset}
              >
                Clear
              </Button>
              <Button
                style={{ marginLeft: 8 }}
                type="submit"
                name="action"
                value="save"
                variant="contained"
                color="primary"
                startIcon={<Save />}
                disabled={isUpdateLoading}
              >
                Save
              </Button>
            </Box>

            {isLoading ? (
              <Box display="flex">
                <CircularProgress className={classes.progress} />
              </Box>
            ) : (
              <Box marginTop={4}>
                <Input
                  name="host"
                  label="Host"
                  value={emailServerSettings?.host}
                  validationErrors={validationErrors}
                />

                <InputNumber
                  name="port"
                  label="Port"
                  value={emailServerSettings?.port}
                  validationErrors={validationErrors}
                />

                <Select
                  allowEmpty
                  name="encryption"
                  label="Encryption"
                  value={emailServerSettings?.encryption}
                  labelWidth={120}
                  validationErrors={validationErrors}
                  options={{ tls: "TLS", ssl: "SSL" }}
                />

                <Input
                  name="username"
                  label="Username"
                  value={emailServerSettings?.username}
                  validationErrors={validationErrors}
                />

                <InputPassword
                  name="password"
                  label="Password"
                  value={emailServerSettings?.password}
                  validationErrors={validationErrors}
                />

                <Input
                  name="fromEmail"
                  label="From email"
                  value={emailServerSettings?.fromEmail}
                  validationErrors={validationErrors}
                />

                <Input
                  name="fromName"
                  label="From name"
                  value={emailServerSettings?.fromName}
                  validationErrors={validationErrors}
                />
              </Box>
            )}
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}
