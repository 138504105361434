import { useHistory, useParams } from "react-router";
import { useEffect } from "react";
import { getMe } from "../../api";
import axios from "axios";

export default function WithToken() {
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    (async function () {
      if (token) {
        try {
          window.localStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          const { user } = await getMe();
          window.localStorage.setItem("user", JSON.stringify(user));
          history.push("/");
        } catch (e) {
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("tfa_token");
          history.push("/login");
        }
      }
    })();
  }, [history, token]);

  return null;
}
