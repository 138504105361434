import { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getKiosk, updateKiosk } from "../../api";
import { getFormPayload } from "../../utils";
import { useParams } from "react-router";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "auto 8px !important",
  },
  form: {
    marginTop: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(2) + " !important",
  },
}));

export default function Edit() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { siteId, kioskId } = useParams();

  const [kiosk, setKiosk] = useState();

  const { data: kioskData } = useQuery(
    ["sites", siteId, "kiosks", kioskId],
    getKiosk(siteId, kioskId),
    {
      onSuccess: (kiosk) => setKiosk(kiosk),
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );
  useEffect(() => {
    setKiosk(kioskData);
  }, [kioskData]);

  const {
    mutate: doUpdateKiosk,
    isLoading,
    error,
  } = useMutation(updateKiosk, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries(["sites", siteId, "kiosks"]);
      queryClient.invalidateQueries(["sites", "tree"]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const handleChange = (key) => (e) =>
    setKiosk({ ...kiosk, [key]: e.target.value });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const kiosk = getFormPayload(e.currentTarget);
      doUpdateKiosk({ kioskId, siteId, kiosk });
    },
    [kioskId, siteId, doUpdateKiosk]
  );

  const validationErrors = error && error.errors;

  return (
    <Layout>
      <Box display="flex">
        <Link to={`/sites/${siteId}/kiosks`}>
          <IconButton>
            <ArrowBack />
          </IconButton>
        </Link>
        <Typography variant="h5" className={classes.title}>
          Edit kiosk
        </Typography>
      </Box>

      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          name="name"
          className={classes.input}
          value={kiosk?.name || ""}
          onChange={handleChange("name")}
          InputLabelProps={{ shrink: true }}
          label="Name"
          variant="outlined"
          fullWidth
          error={!!validationErrors?.name}
          helperText={validationErrors?.name}
        />

        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disabled={isLoading}
        >
          Submit
        </Button>
      </form>
    </Layout>
  );
}
