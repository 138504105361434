import React, { useEffect, useMemo, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ThemeLayout, { themeList } from "../../../Layouts/ThemeLayout";
import {
  Alert,
  Box,
  Breadcrumbs,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getTooltips, getKiosk, getTheme, updateTheme } from "../../../api";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router";
import { getFormPayload } from "../../../utils";
import { Link } from "react-router-dom";
import ThemeComponent from "../ThemeComponent";
import ImportExport from "../../Settings/ImportExport";
import useSaveAcross from "../../../Components/useSaveAcross";
import KioskType from "../../../Components/KioskType";
import { RouterPrompt } from "../../../Components/RouterPrompt";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "auto 0 !important",
    display: "flex",
    "& img": {
      marginLeft: "20px !important",
      display: "block",
    },
  },
  saveButton: {
    marginLeft: "auto !important",
  },
  progress: {
    margin: "100px auto !important",
  },
  breadcrumbs: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export default function Index({ smartType = null }) {
  const smartLink = smartType ? "/smart" : "";

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { siteId, kioskId, tab, subTab } = useParams();
  const history = useHistory();

  const { data: tooltips } = useQuery("tooltips", getTooltips());

  const themeListFiltered = !!smartType
    ? themeList.serveIT
        .filter((e) => !e.smart)
        .map((e) => {
          e.children = e.children.filter((ee) => !ee.smart);
          return e;
        })
    : themeList.serveIT;

  useEffect(() => {
    if (!tab) {
      const firstTab = themeListFiltered?.[0]?.url;
      if (firstTab)
        history.push(
          `/sites/${siteId}/kiosks/${kioskId}${smartLink}/serveIT/theme/${firstTab}`
        );
    }
    if (!subTab) {
      const firstSubTab = themeListFiltered.find((e) => e.url === tab)
        ?.children?.[0]?.url;
      if (firstSubTab)
        history.push(
          `/sites/${siteId}/kiosks/${kioskId}${smartLink}/serveIT/theme/${tab}/${firstSubTab}`
        );
    }
  }, [tab, subTab, history, siteId, kioskId, smartLink, themeListFiltered]);

  const tabTitle = useMemo(
    () => tab && themeListFiltered.find((e) => e.url === tab).title,
    [tab, themeListFiltered]
  );

  const subTabTitle = useMemo(
    () =>
      tab &&
      subTab &&
      themeListFiltered
        .find((e) => e.url === tab)
        .children.find((e) => e.url === subTab).title,
    [tab, subTab, themeListFiltered]
  );

  const [kiosk, setkiosk] = useState();
  useQuery(["site", siteId, "kiosks", kioskId], getKiosk(siteId, kioskId), {
    onSuccess: (kiosk) => setkiosk(kiosk),
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const [theme, setTheme] = useState({});
  const { isLoading } = useQuery(
    ["theme", kioskId, tab, subTab],
    getTheme(siteId, kioskId, tab, subTab, smartType),
    {
      enabled: !!tab && !!subTab,
      onSuccess: (theme) => setTheme(theme),
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const container = React.useRef(null);

  const [dirty, setDirty] = useState(false);
  const onDirty = () => {
    setDirty(true);
  };

  const {
    mutate: doUpdateTheme,
    isLoading: isUpdateLoading,
    error,
  } = useMutation(updateTheme, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries(["theme", kioskId, tab, subTab]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const onSave = useCallback(
    (saveOption, selectedIds) => {
      doUpdateTheme({
        siteId,
        kioskId,
        tab,
        subTab,
        saveOption,
        selectedIds,
        smartType,
        theme,
      });
      setDirty(false);
    },
    [doUpdateTheme, siteId, kioskId, tab, subTab, theme]
  );

  const [openModal, modal] = useSaveAcross({
    onSave,
    forcedId: `${siteId}-${kioskId}`,
  });

  const handleSubmit = useCallback(
    (form, isPayload = false) => {
      let theme = {};
      if (isPayload) theme = form;
      else theme = getFormPayload(form);
      setTheme(theme);
      openModal();
    },
    [setTheme, openModal]
  );

  const validationErrors = error && error.errors;

  return (
    <ThemeLayout type="serveIT">
      <RouterPrompt
        when={dirty}
        body={
          <>
            Your changes have not been saved.
            <br />
            Are you sure you wish to leave this page?
          </>
        }
        title="Unsaved changes"
      />
      <ImportExport
        metadata={{ siteId, kioskId }}
        cacheKey={["theme", kioskId]}
        type="theme"
      />
      <Box display="flex">
        {kiosk && (
          <Typography variant="h5" className={classes.title}>
            {kiosk.site.name} - {kiosk.name}
            <KioskType type={kiosk.type_str} />
          </Typography>
        )}
        <div className={classes.saveButton} ref={container} />
      </Box>
      {modal}

      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <Link
          color="inherit"
          to={`/sites/${siteId}/kiosks/${kioskId}${smartLink}/serveIT/theme`}
        >
          Theme
        </Link>
        {tabTitle && <Typography color="textPrimary">{tabTitle}</Typography>}
        {subTabTitle && (
          <Typography color="textPrimary">{subTabTitle}</Typography>
        )}
      </Breadcrumbs>

      {tab && isLoading ? (
        <Box display="flex">
          <CircularProgress className={classes.progress} />
        </Box>
      ) : (
        <>
          {tab === "general" && subTab === "advertArea" && (
            <Alert severity="info" style={{ marginBottom: 16 }}>
              The advert area slideshow is displayed in the header section,
              which is not visible on landscape screens.
            </Alert>
          )}
          <ThemeComponent
            isUpdateLoading={isUpdateLoading}
            handleSubmit={handleSubmit}
            container={container}
            tooltips={tooltips?.[tab]}
            theme={theme}
            groups={
              themeListFiltered
                .find((e) => e.url === tab)
                ?.children.find((e) => e.url === subTab)?.groups
            }
            validationErrors={validationErrors}
            onDirty={onDirty}
          />
        </>
      )}
    </ThemeLayout>
  );
}
