import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
  Alert,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useSiteTree from "./useSiteTree";

const useStyles = makeStyles((theme) => ({
  treeView: {
    marginLeft: 24,
    paddingLeft: 24,
    maxHeight: 180,
    overflow: "auto",
  },
}));

export default function useSaveAcross({ onSave, forcedId, type = null }) {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const [selected, setSelected] = useState("kiosk");
  const [onlyChanged, setOnlyChanged] = useState(false);

  const onSelectedChanged = (e) => setSelected(e.target.value);

  const { selectedIds, siteTreeView } = useSiteTree({ forcedId, type });

  const enabled =
    selected !== "kiosks" || (selected === "kiosks" && selectedIds.length > 0);

  const onSavePress = () => {
    closeModal();
    onSave(selected + "-" + (onlyChanged ? "diff" : "all"), selectedIds);
  };

  return [
    openModal,
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby="save-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="save-dialog-title">Save options</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              checked={onlyChanged}
              value={onlyChanged}
              onChange={(e) => {
                if (e.target.checked) {
                  setOnlyChanged(true);
                  if (selected === "kiosk") setSelected("kiosks");
                } else {
                  setOnlyChanged(false);
                }
              }}
            />
          }
          label="Save only changed fields"
        />

        {selected == "global" && (
          <Alert severity="error" sx={{ my: 2 }}>
            You're about to save these changes to all the kiosks on your estate.
            If you continue then every kiosk on your estate will adopt this
            setting. If you don't intend to save to all kiosks, then please
            select the <b>Save on kiosk only</b> option.
          </Alert>
        )}

        <RadioGroup value={selected} onChange={onSelectedChanged}>
          <FormControlLabel
            value="global"
            control={<Radio />}
            label="Save globally"
          />
          <FormControlLabel
            value="site"
            control={<Radio />}
            label="Save across site"
          />
          <FormControlLabel
            value="kiosks"
            control={<Radio />}
            label="Save on selected kiosks"
          />

          <div className={classes.treeView}>
            {selected === "kiosks" && siteTreeView}
          </div>
          <FormControlLabel
            disabled={onlyChanged}
            value="kiosk"
            control={<Radio />}
            label="Save on kiosk only"
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button disabled={!enabled} onClick={onSavePress}>
          Save
        </Button>
      </DialogActions>
    </Dialog>,
  ];
}
