import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "./AppBar";
import { useParams } from "react-router";
import { Grid, ListItemIcon } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";

const drawerWidth = 360;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    width: drawerWidth - 48,
    textAlign: "center",
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    zIndex: "1 !important",
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxSizing: "border-box",
    "& a": {
      color: "inherit",
      textDecoration: "none",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  centerContainer: {
    margin: "0 auto",
  },
  sidebarsContainer: {
    height: "100%",
    display: "flex",
  },
  sidebar: {
    width: drawerWidth / 2,
    "&:first-child": {
      height: "100%",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    },
    "&:nth-child(2)": {
      paddingTop: 44,
    },
  },
}));

const isSelected = (pattern) => {
  return window.location.pathname.endsWith(pattern);
};

const isTabSelected = (pattern) => {
  return window.location.pathname.includes("/theme/" + pattern);
};

const visibilityBox = [{ id: "visible", label: "Visible", type: "checkbox" }];
const textInput = [{ id: "text", label: "Text", type: "text" }];

const generalSettings = {
  url: "general",
  title: "General",
  groups: [
    {
      id: "info",
      title: "Info",
      fields: [
        {
          id: "autoShowHelp",
          label: "Auto show help message",
          type: "checkbox",
        },
      ],
    },
  ],
};

const helpMessageImageOnly = {
  url: "images",
  title: "Images",
  groups: [
    {
      id: "images",
      title: "Images",
      fields: [
        {
          id: "helpMessage",
          label: "Help message image",
          type: "image",
        },
      ],
    },
  ],
};

const childrenBorrow = [
  {
    url: "headings",
    title: "Headings",
    groups: [
      {
        id: "top",
        title: "Top",
        fields: textInput,
      },
      {
        id: "table",
        title: "Table",
        fields: [
          { id: "statusVisible", label: "Status visible", type: "checkbox" },
        ],
      },
    ],
  },
  generalSettings,
  helpMessageImageOnly,
];

const childrenRenew = [
  {
    url: "headings",
    title: "Headings",
    groups: [
      {
        id: "top",
        title: "Top",
        fields: textInput,
      },
      {
        id: "table",
        title: "Table",
        fields: [
          { id: "statusVisible", label: "Status visible", type: "checkbox" },
        ],
      },
    ],
  },
  {
    url: "general",
    title: "General",
    groups: [
      {
        id: "info",
        title: "Info",
        fields: [
          {
            id: "autoShowHelp",
            label: "Auto show help message",
            type: "checkbox",
          },
          {
            id: "hideLost",
            label: "Hide lost items",
            type: "checkbox",
          },
        ],
      },
    ],
  },
  helpMessageImageOnly,
];

const holdITChildrenBorrow = [
  {
    url: "headings",
    title: "Headings",
    groups: [
      {
        id: "top",
        title: "Top",
        fields: textInput,
      },
    ],
  },
  helpMessageImageOnly,
];

export const themeList = {
  serveIT: [
    {
      url: "general",
      title: "General",
      children: [
        {
          url: "images",
          title: "Images",
          smart: true,
          groups: [
            {
              id: "images",
              title: "Images",
              fields: [
                { id: "headerLogo", label: "Header logo", type: "image" },
                { id: "headingImg", label: "Heading image", type: "image" },
                { id: "bgImg", label: "Background image", type: "image" },
                //   { id: "rightImg", label: "Right image", type: "image" },
                //   { id: "leftImg", label: "Left image", type: "image" },
              ],
            },
          ],
        },
        {
          url: "advertArea",
          title: "Advert Area",
          groups: [
            {
              id: "settings",
              title: "Settings",
              fields: [
                {
                  id: "disabled",
                  label: "Hide advert area",
                  type: "checkbox",
                },
                {
                  id: "progressDots",
                  label: "Show progress dots",
                  type: "checkbox",
                },
                {
                  id: "pauseSlideshow",
                  label: "Pause slideshow",
                  type: "checkbox",
                },
                {
                  id: "imageDuration",
                  label: "Image duration (seconds)",
                  type: "number",
                },
                {
                  id: "transitionTime",
                  label: "Transition time (seconds)",
                  type: "number",
                },
                {
                  id: "transitionOnClick",
                  label: "Transition on click",
                  type: "checkbox",
                },
                {
                  id: "slideDirection",
                  label: "Slide show direction",
                  type: "select",
                  options: {
                    up: "Up",
                    down: "Down",
                    left: "Left",
                    right: "Right",
                  },
                },
              ],
            },
            {
              id: "images",
              title: "Slideshow images",
              help: "advertArea{images}",
              fields: [{ id: "images", type: "image-multiple", max: 8 }],
            },
          ],
        },
        {
          url: "idleScreen",
          title: "Idle Screen",
          groups: [
            {
              id: "settings",
              title: "Settings",
              fields: [
                {
                  id: "type",
                  label: "Display Content",
                  type: "select",
                  options: {
                    video: "Video",
                    slideshow: "Slideshow images",
                  },
                },
                {
                  id: "idleScreenRes",
                  label: "Video",
                  type: "video",
                  show: (form, data) => {
                    const type =
                      form["settings[type]"] !== ""
                        ? form["settings[type]"]
                        : data?.settings?.type;
                    return type === "video";
                  },
                },
                {
                  id: "slideshow",
                  label: "Slideshow images",
                  type: "image-multiple",
                  max: 8,
                  show: (form, data) => {
                    const type =
                      form["settings[type]"] !== ""
                        ? form["settings[type]"]
                        : data?.settings?.type;
                    return type === "slideshow";
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      url: "welcome",
      title: "Welcome",
      children: [
        {
          url: "buttons",
          title: "Buttons",
          groups: [
            {
              id: "borrow",
              title: "Borrow button",
              fields: visibilityBox,
            },
            {
              id: "return",
              title: "Return button",
              fields: visibilityBox,
            },
            {
              id: "renew",
              title: "Renew button",
              fields: visibilityBox,
            },
            {
              id: "pay",
              title: "Pay button",
              fields: visibilityBox,
            },
            {
              id: "reservation",
              title: "Reservation button",
              fields: visibilityBox,
            },
            {
              id: "thirdParty",
              title: "Third party button",
              fields: visibilityBox,
            },
          ],
        },
        generalSettings,
        {
          url: "headings",
          title: "Headings",
          groups: [
            {
              id: "top",
              title: "Top",
              fields: [
                ...textInput,
                { id: "fontSize", label: "Font size", type: "number" },
                { id: "foreground", label: "Font color", type: "color" },
              ],
            },
          ],
        },
        {
          url: "images",
          title: "Images",
          groups: [
            {
              id: "images",
              title: "Images",
              fields: [
                { id: "issueBtn", label: "Issue button", type: "image" },
                { id: "returnBtn", label: "Return button", type: "image" },
                { id: "renewBtn", label: "Renew button", type: "image" },
                { id: "payBtn", label: "Pay button", type: "image" },
                {
                  id: "reservationBtn",
                  label: "Reservation button",
                  type: "image",
                },
                {
                  id: "thirdPartyBtn",
                  label: "Third party button",
                  type: "image",
                },
                {
                  id: "helpMessage",
                  label: "Help message image",
                  type: "image",
                },
                {
                  id: "itemPickUp",
                  help: "images{itemPickUp}",
                  label: "Item Pick-Up button",
                  type: "image",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      url: "login",
      title: "Login",
      children: [
        {
          url: "buttons",
          title: "Buttons",
          groups: [
            //   {
            //     id: "helpBtn",
            //     title: "Help button",
            //     fields: visibilityBox,
            //   },
            //   {
            //     id: "backBtn",
            //     title: "Back button",
            //     fields: visibilityBox,
            //   },
            {
              id: "keypadBtn",
              title: "Keypad button",
              fields: visibilityBox,
            },
            {
              id: "loginImg",
              title: "Login image",
              fields: [
                {
                  id: "loginMediaType",
                  label: "Login media type",
                  type: "radio",
                  options: {
                    image: "Image",
                    video: "Video",
                  },
                },
                {
                  id: "loginImg",
                  label: "Login image",
                  type: "select",
                  options: {
                    Barcode: "Barcode",
                    MIFARE: "MIFARE",
                    Magstripe: "Magstripe",
                  },
                  show: (form, data) => {
                    const type =
                      form["loginImg[loginMediaType]"] !== ""
                        ? form["loginImg[loginMediaType]"] ??
                          data?.loginImg?.loginMediaType
                        : data?.loginImg?.loginMediaType;
                    return type === "image";
                  },
                },
                {
                  id: "loginVideo",
                  label: "Login video",
                  type: "select",
                  options: {
                    Barcode: "Barcode",
                    MIFARE: "MIFARE",
                    Magstripe: "Magstripe",
                  },
                  show: (form, data) => {
                    const type =
                      form["loginImg[loginMediaType]"] !== ""
                        ? form["loginImg[loginMediaType]"] ??
                          data?.loginImg?.loginMediaType
                        : data?.loginImg?.loginMediaType;
                    return type === "video";
                  },
                },
              ],
            },
          ],
        },
        generalSettings,
        {
          url: "headings",
          title: "Headings",
          groups: [
            {
              id: "top",
              title: "Top",
              fields: textInput,
            },
          ],
        },
        helpMessageImageOnly,
      ],
    },
    {
      url: "pin",
      title: "PIN",
      children: [
        helpMessageImageOnly,
        generalSettings,
        {
          url: "headings",
          title: "Headings",
          groups: [
            {
              id: "top",
              title: "Top",
              fields: textInput,
            },
          ],
        },
      ],
    },
    { url: "borrow", title: "Borrow", children: childrenBorrow },
    { url: "return", title: "Return", children: childrenBorrow },
    { url: "renew", title: "Renew", children: childrenRenew },
    { url: "reservation", title: "Reservation", children: childrenBorrow },
    {
      url: "pay-cash",
      title: "Pay - Cash",
      children: [
        {
          url: "headings",
          title: "Headings",
          groups: [
            {
              id: "top",
              title: "Top",
              fields: textInput,
            },
          ],
        },
        generalSettings,
        helpMessageImageOnly,
      ],
    },
    {
      url: "pay-card",
      title: "Pay - Card",
      children: [
        {
          url: "headings",
          title: "Headings",
          groups: [
            {
              id: "top",
              title: "Top",
              fields: textInput,
            },
          ],
        },
        generalSettings,
        helpMessageImageOnly,
      ],
    },
  ],
  holdIT: [
    {
      url: "general",
      title: "General",
      smart: true,
      children: [
        {
          url: "images",
          title: "Images",
          groups: [
            {
              id: "images",
              title: "Images",
              fields: [
                { id: "headerLogo", label: "Header logo", type: "image" },
                //   { id: "bgImg", label: "Background image", type: "image" },
              ],
            },
          ],
        },
        //   {
        //     url: "idleScreen",
        //     title: "Idle Screen",
        //     groups: [
        //       {
        //         id: "settings",
        //         title: "Settings",
        //         fields: [
        //           {
        //             id: "idleSceenRes",
        //             label: "Idle screen image/video",
        //             type: "image-video",
        //           },
        //         ],
        //       },
        //     ],
        //   },
      ],
    },
    {
      url: "welcome",
      title: "Welcome",
      children: [
        {
          url: "buttons",
          title: "Buttons",
          groups: [
            {
              id: "account",
              title: "Account button",
              fields: visibilityBox,
            },
            {
              id: "return",
              title: "Return button",
              fields: visibilityBox,
            },
          ],
        },
        {
          url: "headings",
          title: "Headings",
          groups: [
            {
              id: "top",
              title: "Top",
              fields: [
                ...textInput,
                { id: "fontSize", label: "Font size", type: "number" },
                { id: "foreground", label: "Font color", type: "color" },
              ],
            },
          ],
        },
        {
          url: "images",
          title: "Images",
          groups: [
            {
              id: "images",
              title: "Images",
              fields: [
                { id: "checkoutBtn", label: "Checkout button", type: "image" },
                { id: "returnBtn", label: "Return button", type: "image" },
                { id: "accountBtn", label: "Account button", type: "image" },
                {
                  id: "switchSSBtn",
                  label: "Switch to self-service button",
                  type: "image",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      url: "login",
      title: "Login",
      children: [
        {
          url: "buttons",
          title: "Buttons",
          groups: [
            //   {
            //     id: "helpBtn",
            //     title: "Help button",
            //     fields: visibilityBox,
            //   },
            //   {
            //     id: "backBtn",
            //     title: "Back button",
            //     fields: visibilityBox,
            //   },
            {
              id: "keypadBtn",
              title: "Keypad button",
              fields: visibilityBox,
            },
            {
              id: "loginImg",
              title: "Login image",
              fields: [
                {
                  id: "loginImg",
                  label: "Login image",
                  type: "select",
                  options: {
                    Barcode: "Barcode",
                    MIFARE: "MIFARE",
                    Magstripe: "Magstripe",
                  },
                },
              ],
            },
          ],
        },
        {
          url: "headings",
          title: "Headings",
          groups: [
            {
              id: "top",
              title: "Top",
              fields: textInput,
            },
          ],
        },
        helpMessageImageOnly,
      ],
    },
    {
      url: "pin",
      title: "PIN",
      children: [
        helpMessageImageOnly,
        {
          url: "headings",
          title: "Headings",
          groups: [
            {
              id: "top",
              title: "Top",
              fields: textInput,
            },
          ],
        },
      ],
    },
    { url: "checkout", title: "Checkout", children: holdITChildrenBorrow },
    {
      url: "return",
      title: "Return",
      children: [
        {
          url: "headings",
          title: "Headings",
          groups: [
            {
              id: "top",
              title: "Top",
              fields: textInput,
            },
            {
              id: "settings",
              title: "Settings",
              fields: [
                {
                  id: "displayMedia",
                  label: "Display media when clicked",
                  type: "checkbox",
                },
                {
                  id: "mediaPopup",
                  label: "Media return pop-up",
                  type: "image-video",
                },
              ],
            },
          ],
        },
        helpMessageImageOnly,
      ],
    },
    { url: "account", title: "Account", children: holdITChildrenBorrow },
  ],
  computeIT: [
    {
      url: "general",
      title: "General",
      children: [
        {
          url: "images",
          title: "Images",
          smart: true,
          groups: [
            {
              id: "images",
              title: "Images",
              fields: [
                { id: "homeLogo", label: "Home screen logo", type: "image" },
                { id: "footerLogo", label: "Footer logo", type: "image" },
                { id: "bgImage", label: "Background image", type: "image" },
              ],
            },
          ],
        },
        {
          url: "settings",
          title: "Settings",
          groups: [
            {
              id: "settings",
              title: "Settings",
              fields: [
                {
                  id: "disableItemStorage",
                  label: "Disable item storage button",
                  type: "checkbox",
                },
                {
                  id: "disableAccountBtn",
                  label: "Disable account button",
                  type: "checkbox",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      url: "help",
      title: "Help",
      children: [
        {
          url: "images",
          title: "Images",
          groups: [
            {
              id: "images",
              title: "Images",
              fields: [
                {
                  id: "loanScreenMedia",
                  label: "Loan screen media",
                  type: "image-video",
                },
                {
                  id: "returnScreenMedia",
                  label: "Return screen media",
                  type: "image-video",
                },
                {
                  id: "itemStorageScreenMedia",
                  label: "Item storage screen media",
                  type: "image-video",
                },
              ],
            },
          ],
        },
        {
          url: "idleScreen",
          title: "Idle Screen",
          groups: [
            {
              id: "settings",
              title: "Settings",
              fields: [
                {
                  id: "type",
                  label: "Display Content",
                  type: "select",
                  options: {
                    video: "Video",
                    slideshow: "Slideshow images",
                  },
                },
                {
                  id: "idleScreenRes",
                  label: "Video",
                  type: "video",
                  show: (form, data) => {
                    const type =
                      form["settings[type]"] !== ""
                        ? form["settings[type]"]
                        : data?.settings?.type;
                    return type === "video";
                  },
                },
                {
                  id: "slideshow",
                  label: "Slideshow images",
                  type: "image-multiple",
                  max: 8,
                  show: (form, data) => {
                    const type =
                      form["settings[type]"] !== ""
                        ? form["settings[type]"]
                        : data?.settings?.type;
                    return type === "slideshow";
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      url: "design",
      title: "Design",
      smart: true,
      children: [
        {
          url: "colors",
          title: "Colours",
          groups: [
            {
              id: "colors",
              title: "Colours",
              fields: [
                { id: "footerColor", label: "Footer colour", type: "color" },
                { id: "buttonColor", label: "Button colour", type: "color" },
                {
                  id: "buttonFontColor",
                  label: "Button font colour",
                  type: "color",
                },
                { id: "bgColor", label: "Background colour", type: "color" },
                { id: "fontColor", label: "Font colour", type: "color" },
              ],
            },
          ],
        },
      ],
    },
  ],
  smart: [
    {
      url: "general",
      title: "General",
      children: [
        {
          url: "images",
          title: "Images",
          groups: [
            {
              id: "images",
              title: "Images",
              fields: [
                { id: "homeLogo", label: "Home screen logo", type: "image" },
                { id: "footerLogo", label: "Footer logo", type: "image" },
                { id: "bgImage", label: "Background image", type: "image" },
              ],
            },
          ],
        },
      ],
    },
    {
      url: "design",
      title: "Design",
      children: [
        {
          url: "colors",
          title: "Colours",
          groups: [
            {
              id: "colors",
              title: "Colours",
              fields: [
                { id: "footerColor", label: "Footer colour", type: "color" },
                { id: "buttonColor", label: "Button colour", type: "color" },
                {
                  id: "buttonFontColor",
                  label: "Button font colour",
                  type: "color",
                },
                { id: "bgColor", label: "Background colour", type: "color" },
                { id: "fontColor", label: "Font colour", type: "color" },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default function ThemeLayout({ children, smart, type }) {
  const classes = useStyles();
  const { siteId, kioskId, tab } = useParams();

  const smartLink = smart ? "/smart" : "";

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar />

      <Drawer
        open={true}
        variant="permanent"
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />

        <div className={classes.sidebarsContainer}>
          <div className={classes.sidebar}>
            <Link to={`/sites/${siteId}/kiosks/${kioskId}`}>
              <ListItem button>
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText
                  primary="Back"
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                ></ListItemText>
              </ListItem>
            </Link>
            {themeList[type]
              .filter((e) => (smart ? !e.smart : true))
              .map((e) => (
                <Link
                  key={e.url}
                  to={`/sites/${siteId}/kiosks/${kioskId}${smartLink}/${type}/theme/${e.url}`}
                >
                  <ListItem button selected={isTabSelected(e.url)}>
                    <ListItemText
                      primary={e.title}
                      primaryTypographyProps={{
                        variant: "body2",
                      }}
                    ></ListItemText>
                  </ListItem>
                </Link>
              ))}
          </div>
          <div className={classes.sidebar}>
            {themeList[type]
              .find((e) => e.url === tab)
              ?.children?.filter((e) => (smart ? !e.smart : true))
              ?.map((e) => (
                <Link
                  key={e.url}
                  to={`/sites/${siteId}/kiosks/${kioskId}${smartLink}/${type}/theme/${tab}/${e.url}`}
                >
                  <ListItem button selected={isSelected(`${tab}/${e.url}`)}>
                    <ListItemText
                      primary={e.title}
                      primaryTypographyProps={{
                        variant: "body2",
                      }}
                    ></ListItemText>
                  </ListItem>
                </Link>
              ))}
          </div>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={9} lg={6} className={classes.centerContainer}>
            {children}
          </Grid>
        </Grid>
      </main>
    </div>
  );
}
