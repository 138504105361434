import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Layout from "../Layouts/AppLayout";
import { Box, Grid, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  address: {
    padding: theme.spacing(4),
  },
}));

export default function HelpSupport() {
  const classes = useStyles();

  return (
    <Layout>
      <Typography variant="h5">Help/Support</Typography>

      <Box marginTop={2} marginBottom={4}>
        <Typography paragraph>
          If you have a problem with your D-Tech product, please click on the
          link below which will take you to our support website where you will
          be required to register (if you have not done so already). Once you
          have registered you will be able to log a ticket for our dedicated
          support team to action.
          <br />
          <br />
          <a href="https://d-techinternational.com/technical-support/">
            https://d-techinternational.com/technical-support
          </a>
          <br />
          <br />
          Please visit our online shop for the latest deals on our library
          accessories range!
          <br />
          <br />
          <a href="https://www.d-techshop.com">https://www.d-techshop.com</a>
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.address}>
            <Typography variant="h6">United Kingdom (HQ)</Typography>
            <Typography paragraph>
              D-Tech International Ltd
              <br />
              Building 136
              <br />
              Bentwaters Parks
              <br />
              Rendlesham
              <br />
              Woodbridge
              <br />
              Suffolk
              <br />
              IP12 2TW
              <br />
              +44 (0)1394 420077
              <br />
              info@d-techinternational.com
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.address}>
            <Typography variant="h6">USA</Typography>
            <Typography paragraph>
              D-Tech International USA LLC
              <br />
              Unit 1<br />
              251 Ranger Road
              <br />
              Rio Grande
              <br />
              New Jersey
              <br />
              08242
              <br />
              (609) 435-5846
              <br />
              Toll Free: 855-38-DTECH
              <br />
              usainfo@d-techinternational.com
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}
