import serveIT from "../res/serveIT.svg";
import holdIT from "../res/holdIT.svg";
import computeIT from "../res/computeIT.svg";
import smart from "../res/smart.svg";

export function typeNumToStr(type) {
  if (type === 0) return "serveIT";
  if (type === 1) return "holdIT";
  if (type === 2) return "computeIT";
  if (type === 3) return "smartIT";
}

export default function KioskType({ type }) {
  return type === "serveIT" ? (
    <img src={serveIT} width={50} alt="serveIT Kiosk" />
  ) : type === "holdIT" ? (
    <img src={holdIT} width={42} style={{ marginLeft: 2 }} alt="holdIT Kiosk" />
  ) : type === "computeIT" ? (
    <img
      src={computeIT}
      width={74}
      style={{ marginLeft: 2 }}
      alt="computeIT Kiosk"
    />
  ) : (
    <img src={smart} width={56} style={{ marginLeft: 2 }} alt="SMART Kiosk" />
  );
}
