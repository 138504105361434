import { makeStyles } from "@material-ui/core/styles";
import Layout from "../Layouts/AppLayout";
import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { getActivityLog, getSites } from "../api";
import { useSnackbar } from "notistack";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator,
} from "@material-ui/lab";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(4),
  },
  createButton: {
    marginLeft: "auto !important",
  },
  progress: {
    margin: "100px auto !important",
  },
  centerContainer: {
    margin: "0 auto",
  },
}));

export default function ActivityLog() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [site, setSite] = useState("all");

  const { data: sites } = useQuery(["sites"], getSites(), {
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });

  const { isLoading, data: activityLog } = useQuery(
    ["activity-log", site],
    getActivityLog(site),
    {
      onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
    }
  );

  const onSiteChange = (e) => {
    setSite(e.target.value);
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9} lg={6} className={classes.centerContainer}>
          <Box display="flex">
            <Typography variant="h5">Activity Log</Typography>
          </Box>

          <Box display="flex">
            {sites && (
              <FormControl sx={{ mt: 4, minWidth: 300 }} size="small">
                <InputLabel>Selected site</InputLabel>
                <Select
                  value={site}
                  onChange={onSiteChange}
                  label="Selected site"
                >
                  <MenuItem value={`all`}>All sites</MenuItem>
                  {sites.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          {isLoading ? (
            <Box display="flex">
              <CircularProgress className={classes.progress} />
            </Box>
          ) : activityLog.length > 0 ? (
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.1,
                },
              }}
            >
              {activityLog.map((event) => (
                <TimelineItem key={event.id}>
                  <TimelineOppositeContent
                    sx={{ fontSize: 12 }}
                    color="textSecondary"
                  >
                    {event.created_at}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography
                      fontWeight={500}
                      dangerouslySetInnerHTML={{ __html: event.message }}
                    ></Typography>
                    <Typography variant="caption">{event.user.name}</Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          ) : (
            <Box
              display="flex"
              mt={8}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <Typography variant="overline">
                No event was logged for this site
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}
