import { useState } from "react";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Paper,
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Autocomplete,
  Chip,
  TextField,
  DialogActions,
  Alert,
} from "@material-ui/core";
import { Email, GetApp, Print, Today } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  printZReport,
  updateZDate,
  getZReport,
  downloadZReport,
  emailZReport,
} from "../api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import Layout from "../Layouts/AppLayout";
import { LoadingButton } from "@material-ui/lab";
import printJS from "print-js";
import { useConfirm } from "material-ui-confirm";
import useSiteTree from "../Components/useSiteTree";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
  treeItem: {
    backgroundColor: "transparent !important",
    height: 40,
  },
  progress: {
    margin: "100px auto !important",
  },
  table: {
    marginTop: 16,
  },
}));

export default function ZReport() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const [payments, setPayments] = useState({});
  const [includeLibrary, setIncludeLibrary] = useState(true);
  const [includeCouncil, setIncludeCouncil] = useState(true);

  const { selectedIds, siteTreeView } = useSiteTree({});

  const { isLoading: paymentsLoading } = useQuery(
    ["z-report", selectedIds, includeLibrary, includeCouncil],
    getZReport(selectedIds, includeLibrary, includeCouncil),
    {
      enabled: selectedIds.length > 0,
      onSuccess: (payments) => setPayments(payments),
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const { mutate: doPrint, isLoading: printLoading } = useMutation(
    printZReport,
    {
      onSuccess: ({ data }) => {
        printJS(URL.createObjectURL(data));
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const onPrint = () => {
    doPrint({ selectedIds, includeLibrary, includeCouncil });
  };

  const { mutate: doUpdateZDate } = useMutation(updateZDate, {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries("z-report");
      enqueueSnackbar(message, { variant: "success" });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const onUpdateZDate = async () => {
    try {
      await confirm({
        title: "Update Z-Date",
        description:
          "Are you sure you want to update the Z-Date for selected kiosks, this action is permanent",
        confirmationText: "update",
      });
      doUpdateZDate({ selectedIds });
    } catch (e) {}
  };

  const [emailOpen, setEmailOpen] = useState(false);
  const [emails, setEmails] = useState([]);

  const onEmailClose = () => {
    setEmails([]);
    setEmailOpen(false);
  };
  const onEmailOpen = () => {
    setEmails([]);
    setEmailOpen(true);
  };

  const { mutate: doEmail } = useMutation(emailZReport, {
    onSuccess: ({ message }) => {
      onEmailClose();
      enqueueSnackbar(message, { variant: "success" });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const onEmailsChange = (e, values) => setEmails(values);

  const onEmailSend = () => {
    doEmail({ selectedIds, emails, includeLibrary, includeCouncil });
  };

  const { mutate: doDownload } = useMutation(downloadZReport, {
    onSuccess: ({ data }) => {
      saveAs(data, `z-report.csv`);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const onDownload = () => {
    doDownload({ selectedIds, emails, includeLibrary, includeCouncil });
  };

  return (
    <Layout>
      <Typography variant="h5">Z-Report</Typography>

      <Grid container spacing={1} marginTop={2}>
        <Grid item xs={12} md={2}>
          <FormControl component="fieldset" style={{ marginBottom: 24 }}>
            <FormLabel component="legend" style={{ marginBottom: 8 }}>
              Include
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={includeLibrary}
                    onChange={(e) => setIncludeLibrary(e.target.checked)}
                  />
                }
                label="Library"
              />
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={includeCouncil}
                    onChange={(e) => setIncludeCouncil(e.target.checked)}
                  />
                }
                label="Council"
              />
            </FormGroup>
          </FormControl>

          {siteTreeView}
        </Grid>

        <Grid item xs={12} md={10}>
          {paymentsLoading ? (
            <Box display="flex">
              <CircularProgress className={classes.progress} />
            </Box>
          ) : (
            <>
              <Box display="flex">
                <Button startIcon={<Today />} onClick={onUpdateZDate}>
                  Save z-date
                </Button>

                <Button
                  startIcon={<Email />}
                  onClick={onEmailOpen}
                  style={{ marginLeft: "auto" }}
                >
                  Email
                </Button>
                <Dialog
                  open={emailOpen}
                  onClose={onEmailClose}
                  aria-labelledby="email-dialog-title"
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle id="email-dialog-title">
                    Send report via email
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <Alert severity="info" style={{ marginBottom: 16 }}>
                        Please enter email addresses, press Enter after each
                        email.
                      </Alert>
                    </DialogContentText>
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      onChange={onEmailsChange}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Email address" />
                      )}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onEmailClose}>Cancel</Button>
                    <Button onClick={onEmailSend}>Send</Button>
                  </DialogActions>
                </Dialog>

                <Button
                  startIcon={<GetApp />}
                  onClick={onDownload}
                  style={{ marginLeft: 4 }}
                >
                  Export
                </Button>

                <LoadingButton
                  startIcon={<Print />}
                  onClick={onPrint}
                  loading={printLoading}
                  loadingPosition="start"
                  style={{ marginLeft: 4 }}
                >
                  Print
                </LoadingButton>
              </Box>
              <TableContainer component={Paper} className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Site</TableCell>
                      <TableCell>Kiosk</TableCell>
                      <TableCell>Z-Date</TableCell>
                      <TableCell>Cash</TableCell>
                      <TableCell>Card</TableCell>
                      <TableCell>Change given</TableCell>
                      <TableCell>Total takings</TableCell>
                      <TableCell>Coin float (Last updated)</TableCell>
                      <TableCell>Notes (Last updated)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payments && payments.length > 0 ? (
                      payments.map((payment, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {payment.site}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {payment.kiosk}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {payment.z_date}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {payment.cash || "0.00"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {payment.card || "0.00"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {payment.change || "0.00"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {payment.total || "0.00"}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {payment.float && `${payment.float} `}
                            {payment.float_reset_at &&
                              `(${payment.float_reset_at})`}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {payment.notes && `${payment.notes} `}
                            {payment.notes_reset_at &&
                              `(${payment.notes_reset_at})`}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No payments found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}
