import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Delete, ArrowBack, Edit, Add, Done, Cancel } from "@material-ui/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteKiosk, getSite, getKiosks } from "../../api";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import KioskType from "../../Components/KioskType";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "auto 8px !important",
  },
  table: {
    marginTop: theme.spacing(4),
  },
  createButton: {
    marginLeft: "auto !important",
  },
  progress: {
    margin: "100px auto !important",
  },
  okChip: {
    background: green[600],
  },
  errorChip: {
    background: red[600],
  },
}));

export default function Index() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { siteId } = useParams();

  const { data: site } = useQuery(["sites", siteId], getSite(siteId));

  const { isLoading, data: kiosks } = useQuery(
    ["sites", siteId, "kiosks"],
    getKiosks(siteId),
    {
      onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
    }
  );

  const { mutate: doDeleteKiosk } = useMutation(deleteKiosk, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries(["sites", siteId, "kiosks"]);
      queryClient.invalidateQueries(["sites", "tree"]);
    },
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });

  const confirm = useConfirm();
  const onDelete = (id) => async () => {
    try {
      await confirm({
        title: "Delete kiosk",
        description:
          "Are you sure you want to delete this kiosk, this action will delete all settings, stats and data for this kiosk, this is permanent",
        confirmationText: "delete",
      });
      doDeleteKiosk({ siteId, id });
    } catch (e) {}
  };

  return (
    <Layout>
      <Box display="flex">
        {site && (
          <Box display="flex">
            <Link to="/sites">
              <IconButton>
                <ArrowBack />
              </IconButton>
            </Link>
            <Typography variant="h5" marginBottom={2} className={classes.title}>
              Site {site.name} - Kiosks
            </Typography>
          </Box>
        )}
        <Link
          to={`/sites/${siteId}/kiosks/create`}
          className={classes.createButton}
        >
          <Button variant="contained" color="primary" startIcon={<Add />}>
            Create
          </Button>
        </Link>
      </Box>

      {isLoading ? (
        <Box display="flex">
          <CircularProgress className={classes.progress} />
        </Box>
      ) : (
        <TableContainer component={Paper} className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Version</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {kiosks && kiosks.length > 0 ? (
                kiosks.map((kiosk) => (
                  <TableRow key={kiosk.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/sites/${siteId}/kiosks/${kiosk.id}`}>
                        {kiosk.name}
                      </Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <KioskType type={kiosk.type_str} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {kiosk.status === 1 ? (
                        <Chip
                          className={classes.okChip}
                          icon={<Done />}
                          label="OK"
                          color="primary"
                        />
                      ) : (
                        <Chip
                          className={classes.errorChip}
                          icon={<Cancel />}
                          label={kiosk.message}
                          color="primary"
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {kiosk.metadata?.version && (
                        <Chip
                          size="small"
                          color="primary"
                          label={kiosk.metadata?.version}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <Link to={`/sites/${siteId}/kiosks/${kiosk.id}/edit`}>
                        <IconButton aria-label="edit" title="Edit">
                          <Edit />
                        </IconButton>
                      </Link>

                      <IconButton
                        aria-label="delete"
                        title="Delete"
                        color="secondary"
                        onClick={onDelete(kiosk.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No kiosks found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Layout>
  );
}
