import LoadingButton from "@material-ui/lab/LoadingButton";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Alert, TextField, CssBaseline } from "@material-ui/core";
import logo from "../../res/manageit-logo-blue.svg";
import dtechLogo from "../../res/logo.png";
import { useMutation } from "react-query";
import axios from "axios";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { getFormPayload } from "../../utils";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginBottom: theme.spacing(1),
  },
  alert: {
    width: "100%",
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  line: {
    marginTop: 30,
    width: "100%",
    border: "none",
    borderBottom: "1px solid #c4c4c4",
  },
}));

export default function Reset() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const {
    mutate: doReset,
    isLoading,
    isError,
    error,
  } = useMutation((credentials) => axios.post("/api/reset", credentials), {
    onSuccess: ({ data }) => {
      enqueueSnackbar(data.message, { variant: "success" });
      history.push("/login");
    },
  });
  const responseErrorMsg = isError
    ? error?.response?.data?.errors?.reset
    : null;
  const responseErrors = isError ? error?.response?.data?.errors : null;

  const reset = (e) => {
    e.preventDefault();
    const credentials = getFormPayload(e.currentTarget);
    doReset(credentials);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img alt="DTech logo" width={160} src={logo} className={classes.logo} />

        {isError && !responseErrors && (
          <Alert className={classes.alert} severity="error">
            Server error
          </Alert>
        )}

        {responseErrorMsg && (
          <Alert className={classes.alert} severity="error">
            {responseErrorMsg}
          </Alert>
        )}

        <form className={classes.form} onSubmit={reset} noValidate>
          <Typography variant="body2">
            Your old password was reset, please set a new password.
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            type="email"
            autoComplete="email"
            autoFocus
            error={!!responseErrors?.email}
            helperText={responseErrors?.email && responseErrors?.email[0]}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            error={!!responseErrors?.password}
            helperText={responseErrors?.password && responseErrors?.password[0]}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password_confirmation"
            label="Confirm password"
            type="password"
            id="password_confirmation"
          />
          <input
            type="hidden"
            name="token"
            value={new URLSearchParams(window.location.search).get("token")}
          />
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Change password
          </LoadingButton>
        </form>
        <hr className={classes.line} />
        <img src={dtechLogo} width={80} alt="D-Tech logo" />
        <Typography variant="caption">© D-Tech International Ltd</Typography>
      </div>
    </Container>
  );
}
