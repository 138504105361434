import { useMemo, useState } from "react";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { useQuery } from "react-query";
import { getSitesTree } from "../api";

const useStyles = makeStyles((theme) => ({
  treeItem: {
    backgroundColor: "transparent !important",
    height: 40,
  },
}));

export default function useSiteTree({ forcedId = null, type = null }) {
  const classes = useStyles();

  const { data: sitesTree } = useQuery(
    ["sites", "tree", type],
    getSitesTree(type),
    {
      staleTime: 180 * 1000,
    }
  );

  const [selected, setSelected] = useState(new Set(forcedId ? [forcedId] : []));
  const handleTreeSelect = (event, nodeId) => {
    let newSelected = new Set([...selected]);
    if (nodeId.includes("-")) {
      if (newSelected.has(nodeId)) {
        newSelected.delete(nodeId);
        const libId = nodeId.split("-")[0];
        newSelected.delete(libId);
      } else newSelected.add(nodeId);
    } else {
      if (newSelected.has(nodeId)) {
        newSelected.delete(nodeId);
        const ids =
          sitesTree
            .find((site) => `${site.id}` === nodeId)
            ?.kiosks?.map((kiosk) => `${kiosk.site_id}-${kiosk.id}`) || [];
        for (let id of ids) newSelected.delete(id);
      } else {
        newSelected = new Set([
          ...newSelected,
          nodeId,
          ...sitesTree
            .find((site) => `${site.id}` === nodeId)
            ?.kiosks?.map((kiosk) => `${kiosk.site_id}-${kiosk.id}`),
        ]);
      }
    }
    if (forcedId) newSelected.add(forcedId);
    setSelected(newSelected);
  };

  const selectedIds = useMemo(
    () =>
      [...selected].filter((e) => e.includes("-")).map((e) => e.split("-")[1]),
    [selected]
  );

  return {
    sitesTree,
    selectedIds,
    siteTreeView: sitesTree && (
      <TreeView
        expanded={sitesTree.map((site) => `${site.id}`)}
        onNodeSelect={handleTreeSelect}
        selected={[...selected]}
        style={{ marginLeft: -12 }}
      >
        {sitesTree.map((site) => (
          <TreeItem
            classes={{
              content: classes.treeItem,
            }}
            key={site.id}
            nodeId={`${site.id}`}
            label={site.name}
            icon={
              selected.has(`${site.id}`) ? (
                <CheckBox fontSize="small" color="primary" />
              ) : (
                <CheckBoxOutlineBlank fontSize="small" color="primary" />
              )
            }
          >
            {site.kiosks &&
              site.kiosks.map((kiosk) => (
                <TreeItem
                  classes={{
                    content: classes.treeItem,
                  }}
                  key={kiosk.id}
                  disabled={`${site.id}-${kiosk.id}` === forcedId}
                  nodeId={`${site.id}-${kiosk.id}`}
                  label={kiosk.name}
                  icon={
                    selected.has(`${site.id}-${kiosk.id}`) ? (
                      <CheckBox fontSize="small" color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank fontSize="small" color="primary" />
                    )
                  }
                />
              ))}
          </TreeItem>
        ))}
      </TreeView>
    ),
  };
}
