import { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { GetApp, Settings, Palette } from "@material-ui/icons";
import { useQuery } from "react-query";
import { getCounts, downloadCounts } from "../../api";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import { ResponsiveBar } from "@nivo/bar";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { canAdmin } from "../../auth";
import DatePicker from "../../Components/DatePicker";
import { last7DaysRange } from "../../Components/DatePicker";
import KioskType from "../../Components/KioskType";
import SmartSettingsIcon from "../../res/smart-settings.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "auto 0 !important",
    display: "flex",
    "& img": {
      marginLeft: "20px !important",
      marginRight: "20px !important",
      display: "block",
    },
  },
  progress: {
    margin: "100px auto !important",
  },
  versionChip: {
    margin: "auto 0px",
  },
}));

export default function ShowRegular({ kiosk }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { siteId, kioskId } = useParams();

  const [counts, setCounts] = useState({});

  const [dateRange, setDateRange] = useState(last7DaysRange);
  const [startDate, endDate] = dateRange;

  const { isLoading: countsLoading } = useQuery(
    ["sites", siteId, "kiosks", kioskId, "counts", startDate, endDate],
    getCounts(siteId, kioskId, startDate, endDate),
    {
      onSuccess: (counts) => setCounts(counts),
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const data = useMemo(() => {
    return counts
      ? Object.entries(counts).map((e) => ({
          key: e[0],
          value: e[1] ? parseInt(e[1]) : 0,
        }))
      : [];
  }, [counts]);

  const onDownload = useCallback(async () => {
    const { data } = await downloadCounts(siteId, kioskId, startDate, endDate);
    saveAs(data, "counts.csv");
  }, [siteId, kioskId, startDate, endDate]);

  return (
    <Layout>
      <Box display="flex">
        <Typography variant="h5" className={classes.title}>
          {kiosk.site.name} - {kiosk.name}
          <KioskType type={kiosk.type_str} />
        </Typography>
        {kiosk?.metadata?.version && (
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={kiosk.metadata.version}
            title={`Running version ${kiosk.metadata.version}`}
            className={classes.versionChip}
          />
        )}
        {canAdmin() && (
          <>
            <Link
              to={`/sites/${siteId}/kiosks/${kioskId}/${kiosk.type_str}/settings`}
              style={{ marginLeft: kiosk.type === 3 ? 16 : "auto" }}
            >
              <IconButton title="Settings" size="small">
                <Settings sx={{ fontSize: 26 }} />
              </IconButton>
            </Link>

            {kiosk.type_str != "computeIT" && (
              <Link
                to={`/sites/${siteId}/kiosks/${kioskId}/${kiosk.type_str}/theme`}
                style={{ marginLeft: 16 }}
              >
                <IconButton title="Theme" size="small">
                  <Palette sx={{ fontSize: 26 }} />
                </IconButton>
              </Link>
            )}
          </>
        )}
      </Box>

      <Box marginTop={4} marginBottom={4}>
        <DatePicker
          onChange={setDateRange}
          element={
            <Button
              startIcon={<GetApp />}
              onClick={onDownload}
              style={{ marginLeft: "auto" }}
            >
              Export
            </Button>
          }
        />
      </Box>

      {countsLoading ? (
        <Box display="flex">
          <CircularProgress className={classes.progress} />
        </Box>
      ) : (
        <Paper>
          <div style={{ height: "400px", width: "99%" }}>
            <ResponsiveBar
              labelSkipHeight={16}
              colorBy="indexValue"
              margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
              axisBottom={{
                format: (value) => {
                  return value
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                },
              }}
              axisLeft={false}
              isInteractive={false}
              data={data}
              padding={0.3}
              indexBy="key"
              keys={["value"]}
            />
          </div>
        </Paper>
      )}
    </Layout>
  );
}
