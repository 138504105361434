import LoadingButton from "@material-ui/lab/LoadingButton";
import {
  CssBaseline,
  TextField,
  Container,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../res/manageit-logo-blue.svg";
import dtechLogo from "../../res/logo.png";
import { useMutation } from "react-query";
import axios from "axios";
import { useHistory } from "react-router";
import InputMask from "react-input-mask";
import { getTFAToken } from "../../auth";
import { getFormPayload } from "../../utils";
import { tfa } from "../../api";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginBottom: theme.spacing(1),
  },
  alert: {
    width: "100%",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  line: {
    marginTop: 30,
    width: "100%",
    border: "none",
    borderBottom: "1px solid #c4c4c4",
  },
}));

export default function TFA() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: doVerify,
    isLoading,
    error,
  } = useMutation(tfa, {
    onSuccess: ({ token, user }) => {
      window.localStorage.removeItem("tfa_token");
      window.localStorage.setItem("token", token);
      window.localStorage.setItem("user", JSON.stringify(user));
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      history.push("/");
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const verify = (e) => {
    e.preventDefault();
    const data = getFormPayload(e.currentTarget);
    doVerify({
      code: data.code.replace(/\s/g, ""),
      trust_device: data.trust_device,
      token: getTFAToken() || "",
    });
  };

  const validationErrors = error && error.errors;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          alt="manageIT logo"
          width={160}
          src={logo}
          className={classes.logo}
        />

        <Typography variant="body1">
          We sent a 6 digits code to your email, please enter it to verify it's
          you.
        </Typography>
        <form className={classes.form} onSubmit={verify}>
          <InputMask mask="9 9 9 9 9 9">
            {() => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="code"
                label="6 digits code"
                name="code"
                autoFocus
                error={!!validationErrors?.code}
                helperText={validationErrors?.code}
              />
            )}
          </InputMask>

          <FormControlLabel
            control={<Checkbox name="trust_device" value="1" />}
            label="Trust this device for 90 days"
          />

          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Verify
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link to="/login" variant="body2">
                Go back to login
              </Link>
            </Grid>
          </Grid>
        </form>
        <hr className={classes.line} />
        <img src={dtechLogo} width={80} alt="D-Tech logo" />
        <Typography variant="caption">© D-Tech International Ltd</Typography>
      </div>
    </Container>
  );
}
