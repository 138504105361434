import React, { Fragment, useCallback, useState } from "react";
import { Button, Divider, Typography } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { Portal } from "@material-ui/unstyled";
import {
  Input,
  Checkbox,
  FileInput,
  InputNumber,
  Select,
  FileInputMultiple,
  FileImgVideoInput,
  InputColor,
  Radio,
} from "../Settings/Inputs";
import { HelpInput } from "../Settings/Help";

export default function ThemeComponent({
  handleSubmit,
  isUpdateLoading,
  container,
  tooltips,
  theme,
  groups,
  validationErrors = {},
  onDirty,
}) {
  const [update, setUpdate] = useState(null);
  const form = React.useRef(null);

  const onSave = useCallback(() => {
    handleSubmit(form.current);
  }, [handleSubmit]);

  const onUpdate = () => {
    onDirty();
    setTimeout(() => setUpdate({}), 5);
  };

  return (
    <form noValidate autoComplete="off" ref={form}>
      {groups &&
        groups.map((group, i) => (
          <Fragment key={group.id}>
            <Typography variant="h6" marginBottom={2}>
              {group.title}{" "}
              {group.help && (
                <HelpInput
                  style={{
                    display: "inline-block",
                    position: "relative",
                    top: 4,
                  }}
                  text={tooltips?.[group.help]}
                />
              )}
            </Typography>
            {group.fields.map((field, i) => {
              const formData = form.current
                ? Object.fromEntries([...new FormData(form.current).entries()])
                : {};
              const showField = field.show ? field.show(formData, theme) : true;

              return (
                showField && (
                  <Fragment key={field.id}>
                    {field.type === "checkbox" ? (
                      <Checkbox
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={!!theme?.[group.id]?.[field.id]}
                        tooltips={tooltips}
                        validationErrors={validationErrors}
                        onChange={onUpdate}
                      />
                    ) : field.type === "text" ? (
                      <Input
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={theme?.[group.id]?.[field.id]}
                        tooltips={tooltips}
                        validationErrors={validationErrors}
                        onChange={onUpdate}
                      />
                    ) : field.type === "number" ? (
                      <InputNumber
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={theme?.[group.id]?.[field.id]}
                        tooltips={tooltips}
                        validationErrors={validationErrors}
                        onChange={onUpdate}
                      />
                    ) : field.type === "color" ? (
                      <InputColor
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={theme?.[group.id]?.[field.id]}
                        tooltips={tooltips}
                        validationErrors={validationErrors}
                        onChange={onUpdate}
                      />
                    ) : field.type === "color" ? (
                      <InputColor
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={theme?.[group.id]?.[field.id]}
                        tooltips={tooltips}
                        validationErrors={validationErrors}
                      />
                    ) : field.type === "image-video" ? (
                      <FileImgVideoInput
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={theme?.[group.id]?.[field.id]}
                        tooltips={tooltips}
                        helpSide="right"
                        onChange={onUpdate}
                      />
                    ) : field.type === "image" ? (
                      <FileInput
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={theme?.[group.id]?.[field.id]}
                        accept="image/*"
                        tooltips={tooltips}
                        helpSide="right"
                        onChange={onUpdate}
                      />
                    ) : field.type === "image-multiple" ? (
                      <FileInputMultiple
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={theme?.[group.id]?.[field.id] || []}
                        accept="image/*"
                        multiple
                        max={field.max}
                        tooltips={tooltips}
                        helpSide="right"
                        onChange={onUpdate}
                      />
                    ) : field.type === "video" ? (
                      <FileInput
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={theme?.[group.id]?.[field.id]}
                        accept="video/mp4"
                        tooltips={tooltips}
                        helpSide="right"
                        onChange={onUpdate}
                      />
                    ) : field.type === "select" ? (
                      <Select
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={theme?.[group.id]?.[field.id]}
                        tooltips={tooltips}
                        options={field.options}
                        allowEmpty
                        onChange={onUpdate}
                      />
                    ) : field.type === "radio" ? (
                      <Radio
                        name={`${group.id}[${field.id}]`}
                        label={field.label}
                        value={theme?.[group.id]?.[field.id]}
                        tooltips={tooltips}
                        options={field.options}
                        allowEmpty
                        onChange={onUpdate}
                      />
                    ) : null}
                  </Fragment>
                )
              );
            })}
            {i < groups.length - 1 && (
              <Divider style={{ marginBottom: 16, marginTop: 24 }} />
            )}
          </Fragment>
        ))}

      <Portal container={container.current}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={onSave}
          disabled={isUpdateLoading}
        >
          Save
        </Button>
      </Portal>
    </form>
  );
}
