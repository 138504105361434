import { makeStyles } from "@material-ui/core/styles";
import Layout from "../Layouts/AppLayout";
import Gauge from "react-svg-gauge";
import {
  Alert,
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { getHomeData } from "../api";
import { useQuery } from "react-query";
import red from "@material-ui/core/colors/red";
import { Link } from "react-router-dom";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2),
  },
  errorChip: {
    background: red[600],
  },
}));

const theme = {
  axis: {
    legend: {
      text: {
        fill: "#000",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  },
};

export default function Dashboard() {
  const classes = useStyles();

  const { data } = useQuery(`home-data`, getHomeData(), {
    staleTime: 300000,
  });

  return (
    <Layout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {data && data.license_warning && (
            <Alert severity="error">
              Your license will expire on{" "}
              {moment(data.license.end).format("DD/MM/YYYY")}, please contact
              D-Tech for renewal to not experience any downtime.
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              padding={2}
            >
              <Typography variant="h5" style={{ marginBottom: 25 }}>
                Welcome to manageIT
              </Typography>

              {data && data.total > 0 && (
                <Gauge
                  value={data.connected}
                  max={data.total}
                  color="#004a70"
                  backgroundColor="rgb(255, 254, 135)"
                  width={300}
                  height={200}
                  label="Connected kiosks"
                  topLabelStyle={{ fontSize: 18 }}
                />
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          {data && (
            <Paper>
              <div style={{ height: 300, width: "99%" }}>
                <ResponsiveBar
                  labelSkipHeight={16}
                  colorBy="indexValue"
                  margin={{ top: 30, right: 30, bottom: 70, left: 30 }}
                  axisLeft={false}
                  axisBottom={{
                    legend: "Yesterday",
                    legendOffset: 45,
                    legendPosition: "middle",
                  }}
                  isInteractive={false}
                  data={data.yesterday}
                  padding={0.3}
                  indexBy="key"
                  keys={["value"]}
                  theme={theme}
                />
              </div>
            </Paper>
          )}
        </Grid>
        <Grid item xs={6}>
          {data && (
            <Paper>
              <div style={{ height: 300, width: "99%" }}>
                <ResponsiveBar
                  labelSkipHeight={16}
                  colorBy="indexValue"
                  margin={{ top: 30, right: 30, bottom: 70, left: 30 }}
                  axisLeft={false}
                  axisBottom={{
                    legend: "Last 7 days",
                    legendOffset: 45,
                    legendPosition: "middle",
                  }}
                  isInteractive={false}
                  data={data.last7days}
                  padding={0.3}
                  indexBy="key"
                  keys={["value"]}
                  theme={theme}
                />
              </div>
            </Paper>
          )}
        </Grid>
      </Grid>

      {data && data.failed && data.failed.length > 0 && (
        <TableContainer component={Paper} className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Kiosk</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.failed.map((kiosk) => (
                <TableRow key={kiosk.id}>
                  <TableCell component="th" scope="row">
                    <Link to={`/sites/${kiosk.site_id}/kiosks/${kiosk.id}`}>
                      {kiosk.name}
                    </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Chip
                      className={classes.errorChip}
                      icon={<Cancel />}
                      label={kiosk.message}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Layout>
  );
}
