import { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
  Select as MUISelect,
  Checkbox as MUICheckbox,
  Autocomplete as MUIAutocomplete,
  Input as MUIInput,
  FormControlLabel,
  Box,
  RadioGroup,
  FormLabel,
  Radio as MUIRadio,
  IconButton,
  Typography,
  InputAdornment,
  Chip,
} from "@material-ui/core";
import { Help, HelpInput } from "./Help";
import {
  Delete,
  Download,
  Edit,
  Visibility,
  VisibilityOff,
  // IndeterminateCheckBox,
  // IndeterminateCheckBoxOutlined,
} from "@material-ui/icons";
import { uploadImage } from "../../api.js";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { Controller } from "react-hook-form";

function getTooltip(tooltips, name) {
  return tooltips?.[name.replace(/\[/g, "{").replace(/\]/g, "}")];
}

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(2) + " !important",
  },
  checkboxHelp: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: "auto",
    marginBottom: "auto",
    position: "relative",
    top: -3,
    fill: "rgba(0, 0, 0, 0.54)",
  },
  checkboxHelpColor: {
    top: 0,
  },
  checkboxHelpImg: {
    top: 0,
    marginLeft: 32,
  },
  checkboxLabel: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  updateCheckbox: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: theme.spacing(2),
  },
  colorInput: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    "&:before, &:after": {
      border: "none !important",
    },
  },
  imgBox: {
    flexDirection: "row",
    width: "min-content",
    "& button": {
      alignSelf: "center",
      marginLeft: theme.spacing(2),
    },
  },
  previewImgMulti: {
    alignSelf: "flex-start",
    width: "100%",
    height: "auto",
  },
  previewImg: {
    alignSelf: "flex-start",
    width: 200,
    height: "auto",
  },
  imgGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 10,
    marginBottom: 20,
  },

  imgTools: {
    display: "grid",
    gridTemplateAreas: "overlay",
    "& img": {
      gridArea: "overlay",
    },
    "&:hover $imgOverlay": {
      display: "flex",
    },
  },
  imgOverlay: {
    display: "none",
    gridArea: "overlay",
    background: "rgba(0,0,0,.7)",
  },
  multilineParent: {
    "& .MuiInputAdornment-root": {
      alignSelf: "flex-start",
      marginTop: 12,
    },
  },
}));

export function SettingsGrid({ name, children }) {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.input}>
      {children}
      {/* <MUICheckbox
        name={
          name.includes("[")
            ? `updates[${name.replace("[", "][")}`
            : `updates[${name}]`
        }
        value="1"
        defaultChecked={false}
        title="Update"
        color="primary"
        icon={<IndeterminateCheckBoxOutlined fontSize="small" />}
        checkedIcon={<IndeterminateCheckBox fontSize="small" />}
        className={classes.updateCheckbox}
      /> */}
    </Box>
  );
}

export function Input({
  name,
  label,
  value,
  validationErrors,
  tooltips,
  multiline = false,
  InputProps = {},
  onChange = () => {},
}) {
  const classes = useStyles();

  const [text, setText] = useState(
    value !== undefined && value !== null ? value : ""
  );

  useEffect(() => {
    setText(value !== undefined && value !== null ? value : "");
  }, [value]);

  return (
    <SettingsGrid name={name}>
      <TextField
        name={name}
        label={label}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={label}
        variant="outlined"
        fullWidth
        error={!!validationErrors?.[name]}
        helperText={validationErrors?.[name]}
        multiline={multiline}
        className={multiline ? classes.multilineParent : ""}
        InputProps={{
          ...InputProps,
          autoComplete: window.chrome ? "chrome-off" : "off",
          startAdornment: <HelpInput text={getTooltip(tooltips, name)} />,
        }}
      />
    </SettingsGrid>
  );
}

export function InputNumber({ InputProps, ...props }) {
  return (
    <Input
      {...props}
      InputProps={{ type: "number", inputProps: { min: 0 }, ...InputProps }}
    />
  );
}

export function InputPassword({ InputProps, visiblePassword, ...props }) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword, setShowPassword]
  );

  return (
    <Input
      {...props}
      InputProps={{
        autoComplete: window.chrome ? "chrome-off" : "off",
        type: showPassword ? "text" : "password",
        endAdornment: visiblePassword ? (
          <InputAdornment position="end">
            <IconButton onClick={togglePassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : null,
        ...InputProps,
      }}
    />
  );
}

export function InputColor({
  name,
  label,
  value,
  validationErrors,
  tooltips,
  onChange,
}) {
  const classes = useStyles();

  const [color, setColor] = useState(value);

  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <SettingsGrid name={name}>
      <Box display="flex" width="100%">
        <Help
          className={clsx(classes.checkboxHelp, classes.checkboxHelpColor)}
          text={getTooltip(tooltips, name)}
        />
        <IconButton
          aria-label="delete"
          title="Delete"
          color="secondary"
          onClick={() => {
            setColor(null);
            onChange && onChange(null);
          }}
          sx={{
            padding: 0,
            height: "min-content",
            margin: "auto 0",
            marginRight: 4,
          }}
        >
          <Delete />
        </IconButton>
        <FormControl>
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <MUIInput
                className={classes.colorInput}
                type="color"
                value={color || "#FFFFFF"}
                onChange={(e) => {
                  setColor(e.target.value);
                  onChange && onChange(e.target.value);
                }}
              />
            }
            label={label}
          />
          <FormHelperText error={!!validationErrors?.[name]}>
            {validationErrors?.[name]}
          </FormHelperText>
        </FormControl>

        <input type="hidden" name={name} value={color ?? ""} />
      </Box>
    </SettingsGrid>
  );
}

export function Checkbox({
  name,
  label,
  value,
  validationErrors,
  tooltips,
  onChange,
  disabled = false,
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState(!!value);

  useEffect(() => {
    setChecked(!!value);
  }, [value]);

  return (
    <SettingsGrid name={name}>
      <Box display="flex" width="100%">
        <Help
          className={classes.checkboxHelp}
          text={getTooltip(tooltips, name)}
        />
        <FormControl variant="outlined">
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <MUICheckbox
                value="1"
                checked={checked}
                onChange={(e) => {
                  setChecked(e.target.checked);
                  onChange && onChange(e.target.checked);
                }}
                color="primary"
                name={name}
                disabled={disabled}
              />
            }
            label={label}
          />
          <FormHelperText error={!!validationErrors?.[name]}>
            {validationErrors?.[name]}
          </FormHelperText>
        </FormControl>
      </Box>
    </SettingsGrid>
  );
}

export function ControlledCheckbox({
  name,
  value,
  onChange,
  label,
  validationErrors,
  tooltips,
  disabled = false,
}) {
  const classes = useStyles();

  return (
    <SettingsGrid name={name}>
      <Box display="flex" width="100%">
        <Help
          className={classes.checkboxHelp}
          text={getTooltip(tooltips, name)}
        />
        <FormControl variant="outlined">
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <MUICheckbox
                value="1"
                checked={value}
                onChange={(e) => {
                  onChange && onChange(e.target.checked);
                }}
                color="primary"
                name={name}
                disabled={disabled}
              />
            }
            label={label}
          />
          <FormHelperText error={!!validationErrors?.[name]}>
            {validationErrors?.[name]}
          </FormHelperText>
        </FormControl>
      </Box>
    </SettingsGrid>
  );
}

export function RadioSingle({
  name,
  value,
  label,
  checked,
  validationErrors,
  tooltips,
  onChange,
}) {
  const classes = useStyles();

  return (
    <SettingsGrid name={name}>
      <Box display="flex" width="100%">
        <Help
          className={classes.checkboxHelp}
          text={getTooltip(tooltips, name)}
        />
        <FormControl variant="outlined">
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <MUIRadio
                value={value}
                checked={checked}
                onChange={(e) => {
                  onChange && onChange(e.target.value);
                }}
                color="primary"
                name={name}
              />
            }
            label={label}
          />
          <FormHelperText error={!!validationErrors?.[name]}>
            {validationErrors?.[name]}
          </FormHelperText>
        </FormControl>
      </Box>
    </SettingsGrid>
  );
}

export function Select({
  name,
  label,
  value,
  validationErrors,
  tooltips,
  labelWidth,
  options,
  allowEmpty,
  onChange,
  multiple = false,
  style = {},
}) {
  const [activeValue, setActiveValue] = useState(
    multiple ? (Array.isArray(value) ? value : [value]) : value
  );

  useEffect(() => {
    setActiveValue(multiple ? (Array.isArray(value) ? value : [value]) : value);
  }, [value, setActiveValue, multiple]);

  return (
    <SettingsGrid name={name}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <MUISelect
          name={name}
          labelId={`${name}-label`}
          id={name}
          label={label}
          error={!!validationErrors?.[name]}
          value={activeValue ?? ""}
          onChange={(e) => {
            setActiveValue(e.target.value);
            onChange && onChange(e);
          }}
          startAdornment={<HelpInput text={getTooltip(tooltips, name)} />}
          multiple={multiple}
          style={style}
        >
          {allowEmpty && <MenuItem value="">　</MenuItem>}
          {Object.keys(options).map((k) => (
            <MenuItem value={k} key={k}>
              {options[k]}
            </MenuItem>
          ))}
        </MUISelect>
        <FormHelperText error={!!validationErrors?.[name]}>
          {validationErrors?.[name]}
        </FormHelperText>
      </FormControl>
    </SettingsGrid>
  );
}

export function Radio({
  name,
  label,
  value,
  validationErrors,
  tooltips,
  options,
  onChange,
}) {
  const classes = useStyles();
  const [activeValue, setActiveValue] = useState(value ?? "");

  useEffect(() => {
    setActiveValue(value ?? "");
  }, [value, setActiveValue]);

  return (
    <SettingsGrid name={name}>
      <Box display="flex" width="100%">
        <Help
          className={classes.checkboxHelp}
          text={getTooltip(tooltips, name)}
        />
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup
            name={name}
            value={activeValue}
            onChange={(e) => {
              setActiveValue(e.target.value);
              onChange && onChange(e);
            }}
          >
            {Object.keys(options).map((k) => (
              <FormControlLabel
                value={k}
                key={k}
                control={<MUIRadio color="primary" onChange={onChange} />}
                label={options[k]}
              />
            ))}
          </RadioGroup>
          <FormHelperText error={!!validationErrors?.[name]}>
            {validationErrors?.[name]}
          </FormHelperText>
        </FormControl>
      </Box>
    </SettingsGrid>
  );
}

export function ControlledRadio({
  name,
  label,
  value,
  onChange,
  validationErrors,
  tooltips,
  options,
}) {
  const classes = useStyles();

  return (
    <SettingsGrid name={name}>
      <Box display="flex" width="100%">
        <Help
          className={classes.checkboxHelp}
          text={getTooltip(tooltips, name)}
        />
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup
            name={name}
            value={value}
            onChange={(e) => {
              onChange && onChange(e.target.value);
            }}
          >
            {Object.keys(options).map((k) => (
              <FormControlLabel
                value={k}
                key={k}
                control={<MUIRadio color="primary" onChange={onChange} />}
                label={options[k]}
              />
            ))}
          </RadioGroup>
          <FormHelperText error={!!validationErrors?.[name]}>
            {validationErrors?.[name]}
          </FormHelperText>
        </FormControl>
      </Box>
    </SettingsGrid>
  );
}

export function FileInput({
  name,
  label,
  value,
  validationErrors,
  tooltips,
  accept,
  helpSide = "left",
  onChange = () => {},
}) {
  const classes = useStyles();
  // const [progress, setProgress] = useState(false);
  const [path, setPath] = useState(value);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPath(value);
  }, [value]);

  const fileChanged = async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    try {
      const { data } = await uploadImage(
        formData
        // onUploadProgress,
      );
      if (data.success) {
        setTimeout(() => {
          setPath(data.path);
        }, 10);
        onChange(data.path);
      }
    } catch (e) {
      const message = e.response.data?.message;
      if (message) {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  return (
    <SettingsGrid name={name}>
      <Box display="flex" width="100%">
        {helpSide === "left" && (
          <Help
            className={classes.checkboxHelp}
            text={getTooltip(tooltips, name)}
          />
        )}
        <FormControl component="fieldset" fullWidth className={classes.imgBox}>
          <FormLabel component="legend">{label}</FormLabel>
          {path ? (
            <>
              {accept?.includes("video") ? (
                <video
                  alt="preview"
                  className={classes.previewImg}
                  src={path}
                  controls
                />
              ) : (
                <img alt="preview" className={classes.previewImg} src={path} />
              )}
              <IconButton
                aria-label="delete"
                title="Delete"
                color="secondary"
                onClick={() => {
                  setPath(null);
                  onChange(null);
                }}
              >
                <Delete />
              </IconButton>
              <IconButton
                aria-label="download"
                title="Download"
                onClick={() => {
                  window.open(path + "?download=true", "_blank");
                }}
              >
                <Download />
              </IconButton>
              {path && helpSide === "right" && (
                <Help
                  className={clsx(
                    classes.checkboxHelp,
                    classes.checkboxHelpImg
                  )}
                  text={getTooltip(tooltips, name)}
                />
              )}
            </>
          ) : (
            <input type="file" accept={accept} onChange={fileChanged} />
          )}
          <input type="hidden" name={name} value={path ?? ""} />
          <FormHelperText error={!!validationErrors?.[name]}>
            {validationErrors?.[name]}
          </FormHelperText>
        </FormControl>
        {!path && helpSide === "right" && (
          <Help
            className={classes.checkboxHelp}
            text={getTooltip(tooltips, name)}
          />
        )}
      </Box>
    </SettingsGrid>
  );
}

export function FileImgVideoInput({
  name,
  label,
  value,
  validationErrors,
  tooltips,
  helpSide = "left",
  onChange = () => {},
}) {
  const classes = useStyles();
  // const [progress, setProgress] = useState(false);
  const [viRes, setViRes] = useState(value);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setViRes(value);
  }, [value]);

  const fileChanged = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const isImg = file.type.match("image.*");
      let formData = new FormData();
      formData.append("file", file);
      try {
        const { data } = await uploadImage(formData);
        if (data.success) {
          setTimeout(() => {
            onChange({ path: data.path, type: isImg ? "image" : "video" });
            setViRes({ path: data.path, type: isImg ? "image" : "video" });
          }, 10);
        }
      } catch (e) {
        const message = e.response.data?.message;
        if (message) {
          enqueueSnackbar(message, { variant: "error" });
        }
      }
    } else {
      onChange({ path: null, type: null });
      setViRes({ path: null, type: null });
    }
    const isImg = file.type.match("image.*");
    let formData = new FormData();
    formData.append("file", file);
    try {
      const { data } = await uploadImage(
        formData
        // onUploadProgress,
      );
      if (data.success) {
        setTimeout(() => {
          onChange({ path: data.path, type: isImg ? "image" : "video" });
          setViRes({ path: data.path, type: isImg ? "image" : "video" });
        }, 10);
      }
    } catch (e) {
      const message = e.response.data?.message;
      if (message) {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const onReset = () => {
    onChange(null);
    setViRes(null);
  };

  const { path, type } = viRes || {};

  return (
    <SettingsGrid name={name}>
      <Box display="flex" width="100%">
        {helpSide === "left" && (
          <Help
            className={classes.checkboxHelp}
            text={getTooltip(tooltips, name)}
          />
        )}
        <FormControl component="fieldset" fullWidth className={classes.imgBox}>
          <FormLabel component="legend">{label}</FormLabel>
          {path ? (
            <>
              {type === "video" ? (
                <video
                  alt="preview"
                  className={classes.previewImg}
                  src={path}
                  controls
                />
              ) : (
                <img alt="preview" className={classes.previewImg} src={path} />
              )}
              <IconButton
                aria-label="delete"
                title="Delete"
                color="secondary"
                onClick={onReset}
              >
                <Delete />
              </IconButton>
              <IconButton
                aria-label="download"
                title="Download"
                onClick={() => {
                  window.open(path + "?download=true", "_blank");
                }}
              >
                <Download />
              </IconButton>
              {path && helpSide === "right" && (
                <Help
                  className={clsx(
                    classes.checkboxHelp,
                    classes.checkboxHelpImg
                  )}
                  text={getTooltip(tooltips, name)}
                />
              )}
            </>
          ) : (
            <input
              type="file"
              accept="image/*,video/mp4"
              onChange={fileChanged}
            />
          )}
          {path && (
            <>
              <input type="hidden" name={`${name}[path]`} value={path} />
              <input type="hidden" name={`${name}[type]`} value={type} />
            </>
          )}
          <FormHelperText error={!!validationErrors?.[name]}>
            {validationErrors?.[name]}
          </FormHelperText>
        </FormControl>
        {!path && helpSide === "right" && (
          <Help
            className={classes.checkboxHelp}
            text={getTooltip(tooltips, name)}
          />
        )}
      </Box>
    </SettingsGrid>
  );
}

export function FileInputMultiple({
  name,
  label,
  value,
  validationErrors,
  tooltips,
  accept,
  max,
  onChange = () => {},
}) {
  const classes = useStyles();
  // const [progress, setProgress] = useState(false);
  const [paths, setPaths] = useState(value);
  const fileInput = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPaths(value ? Object.values(value)?.filter((e) => e) : value);
  }, [value]);

  const fileChanged = async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    try {
      const { data } = await uploadImage(
        formData
        // onUploadProgress,
      );
      if (data.success) {
        onChange();
        setTimeout(() => setPaths([...paths, data.path]), 10);
        if (fileInput.current) fileInput.current.value = null;
      }
    } catch (e) {
      const message = e.response.data?.message;
      if (message) {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const fileEdited = (path) => async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    try {
      const { data } = await uploadImage(formData);
      if (data.success) {
        onChange();
        setPaths(paths.map((e) => (e === path ? data.path : e)));
        e.target.value = null;
      }
    } catch (e) {
      const message = e.response.data?.message;
      if (message) {
        enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const openInput = (e) => {
    e.target?.closest(".img-tools-parent")?.querySelector("input")?.click();
  };

  const deleteImg = (path) => (e) => {
    onChange();
    setPaths(paths.filter((e) => e !== path));
  };

  return (
    <Box>
      {label && (
        <>
          <Help className={classes.checkboxHelp} text={tooltips?.[name]} />
          <Typography variant="h6">{label}</Typography>
        </>
      )}

      <Box display="flex" width="100%" flexDirection="column">
        {paths && paths?.length > 0 && (
          <Box className={classes.imgGrid}>
            {paths?.map((path, i) => (
              <div className="img-tools-parent" key={`${path}-${i}`}>
                <div className={classes.imgTools}>
                  <img
                    alt="preview"
                    className={classes.previewImgMulti}
                    src={path}
                  />
                  <div className={classes.imgOverlay}>
                    <div style={{ margin: "auto" }}>
                      <IconButton title="Edit" onClick={openInput} size="small">
                        <Edit style={{ color: "#fff" }} />
                      </IconButton>
                      <input
                        value=""
                        style={{ display: "none" }}
                        type="file"
                        accept={accept}
                        onChange={fileEdited(path)}
                      />
                      <IconButton
                        title="Delete"
                        onClick={deleteImg(path)}
                        size="small"
                      >
                        <Delete style={{ color: "#fff" }} />
                      </IconButton>
                      <IconButton
                        aria-label="download"
                        title="Download"
                        onClick={() => {
                          window.open(path + "?download=true", "_blank");
                        }}
                        size="small"
                      >
                        <Download style={{ color: "#fff" }} />
                      </IconButton>
                    </div>
                  </div>
                </div>
                <input
                  type="hidden"
                  name={`${name}[${i}]`}
                  value={path ?? ""}
                />
              </div>
            ))}
          </Box>
        )}

        {(paths?.length || 0) < max && (
          <input
            type="file"
            accept={accept}
            onChange={fileChanged}
            ref={fileInput}
          />
        )}
      </Box>
    </Box>
  );
}

export function InputMultiple({
  name,
  label,
  value,
  validationErrors,
  placeholder,
  tooltips,
  onChange,
}) {
  const [activeValue, setActiveValue] = useState(
    Array.isArray(value)
      ? value
      : value?.split(",").filter((e) => e?.length > 0) ?? []
  );

  const ref = useRef(null);

  useEffect(() => {
    setActiveValue(
      Array.isArray(value)
        ? value
        : value?.split(",").filter((e) => e?.length > 0) ?? []
    );
  }, [value, setActiveValue]);

  const onFieldChange = (e, v) => {
    if (ref.current) ref.current.value = v.join(",");
  };

  return (
    <SettingsGrid name={name}>
      <MUIAutocomplete
        fullWidth
        onChange={onFieldChange}
        defaultValue={activeValue}
        multiple
        freeSolo
        options={[]}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ minWidth: 300 }}
            label={label}
            variant="outlined"
            placeholder={placeholder}
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
            error={!!validationErrors?.[name]}
            helperText={validationErrors?.[name]}
          />
        )}
      />
      <input
        ref={ref}
        type="hidden"
        name={name}
        value={activeValue.join(",")}
      />
    </SettingsGrid>
  );
}
