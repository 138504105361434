import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import QueryStatsIcon from "@material-ui/icons/QueryStats";
import LanguageIcon from "@material-ui/icons/Language";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import PeopleIcon from "@material-ui/icons/People";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";

import {
  Autocomplete,
  Divider,
  ListSubheader,
  TextField,
} from "@material-ui/core";
import { TreeView } from "@material-ui/lab";
import { useHistory } from "react-router";
import { getSitesTree } from "../api";
import { useQuery } from "react-query";
import clsx from "clsx";
import AppBar from "./AppBar";
import { Link } from "react-router-dom";
import { canAdmin, hasFeature } from "../auth";
import { useEffect, useMemo, useRef } from "react";
import { Badge, ForwardToInbox, Search } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    width: drawerWidth - 48,
    textAlign: "center",
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    zIndex: "1 !important",
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxSizing: "border-box",
    "& a": {
      color: "inherit",
      textDecoration: "none",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  accountButtonLabel: {
    textTransform: "none",
  },
  accountButton: {
    marginLeft: "auto !important",
  },
  accountMenu: {
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiListItem-root": {
      ...theme.typography.body2,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 18,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1.5),
    },
  },
  treeLabel: {
    display: "flex",
  },
  treeItem: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    cursor: "default",
  },
  chip: {
    width: 18,
    height: 18,
    borderRadius: 18,
    display: "inline-block",
    marginRight: theme.spacing(2),
    marginTop: "auto",
    marginBottom: "auto",
    flexShrink: 0,
  },
  greyChip: {
    background: grey[600],
  },
  okChip: {
    background: green[600],
  },
  errorChip: {
    background: red[600],
  },
}));

const isSelected = (pattern) => {
  const { pathname } = window.location;
  const matches = pathname.match(/sites\/([0-9]+)\/kiosks\/([0-9]+)/);
  if (!matches) return window.location.pathname.startsWith(pattern);
  return false;
};

const defaultTree = () => {
  const { pathname } = window.location;
  let treeExpanded = [];
  if (localStorage.getItem("sidebar-expanded")) {
    treeExpanded = JSON.parse(localStorage.getItem("sidebar-expanded"));
  }
  const matches = pathname.match(/sites\/([0-9]+)\/kiosks\/([0-9]+)/);
  if (matches && matches[1] && matches[2]) {
    return [[...treeExpanded, matches[1]], [pathname]];
  }
  return [treeExpanded, []];
};

export default function AppLayout({ children }) {
  const classes = useStyles();
  const history = useHistory();

  const handleTreeSelect = (event, nodeIds) => {
    if (nodeIds && nodeIds.startsWith("/")) {
      history.push(nodeIds);
    }
  };

  const { data: sitesTree } = useQuery(["sites", "tree"], getSitesTree(), {
    staleTime: 180 * 1000,
  });

  const kiosksList = useMemo(() => {
    return (
      sitesTree
        ?.map((site) =>
          site.kiosks.map((e) => ({
            id: [site.id, e.id],
            name: `${site.name}/${e.name}`,
          }))
        )
        .reduce((acc, v) => [...acc, ...v], []) ?? []
    );
  }, [sitesTree]);

  const drawerRef = useRef();
  let [treeExpanded, treeSelected] = defaultTree();

  const saveToggleState = (e, nodeIds) => {
    const set = [...new Set(nodeIds)];
    localStorage.setItem("sidebar-expanded", JSON.stringify(set));
  };

  useEffect(() => {
    let element = null;
    if (drawerRef.current) {
      element = drawerRef.current;
      // Reset to previous scroll
      drawerRef.current.scrollTop = parseFloat(
        localStorage.getItem("sidebar-scroll")
      );

      // Add listener
      const saveScroll = () => {
        localStorage.setItem("sidebar-scroll", drawerRef.current.scrollTop);
      };
      drawerRef.current.addEventListener("scroll", saveScroll);
      return () => {
        if (element) element.removeEventListener("scroll", saveScroll);
      };
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar />

      <Drawer
        open={true}
        variant="permanent"
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{ ref: drawerRef }}
      >
        <div className={classes.toolbar} />
        <ListSubheader inset>Site Locations</ListSubheader>
        <Autocomplete
          options={kiosksList}
          style={{ margin: 16, marginTop: 0 }}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              InputProps={{
                ...params.InputProps,
                placeholder: "Search",
                startAdornment: <Search />,
              }}
            />
          )}
          onChange={(e, value) => {
            if (value?.id) {
              const [siteId, kioskId] = value.id;
              history.push(`/sites/${siteId}/kiosks/${kioskId}`);
            }
          }}
          getOptionLabel={(option) => option?.name ?? ""}
        />

        {sitesTree && (
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={treeExpanded}
            defaultSelected={treeSelected}
            onNodeToggle={saveToggleState}
            onNodeSelect={handleTreeSelect}
          >
            {sitesTree.map((site) => (
              <TreeItem
                key={site.id}
                classes={{
                  content: classes.treeItem,
                  label: classes.treeLabel,
                }}
                nodeId={`${site.id}`}
                label={
                  <div
                    style={{ cursor: "pointer", display: "flex" }}
                    onClick={() => history.push(`/sites/${site.id}/kiosks`)}
                  >
                    {!site.kiosks || site.kiosks.length === 0 ? (
                      <div className={clsx(classes.chip, classes.greyChip)} />
                    ) : site.status === 1 ? (
                      <div className={clsx(classes.chip, classes.okChip)} />
                    ) : (
                      <div className={clsx(classes.chip, classes.errorChip)} />
                    )}
                    {site.name}
                  </div>
                }
              >
                {site.kiosks &&
                  site.kiosks.map((kiosk) => (
                    <TreeItem
                      key={kiosk.id}
                      classes={{
                        content: classes.treeItem,
                        label: classes.treeLabel,
                      }}
                      nodeId={`/sites/${site.id}/kiosks/${kiosk.id}`}
                      label={
                        <>
                          {kiosk.status === 1 ? (
                            <div
                              className={clsx(classes.chip, classes.okChip)}
                            />
                          ) : (
                            <div
                              className={clsx(classes.chip, classes.errorChip)}
                            />
                          )}
                          {kiosk.name}
                        </>
                      }
                    />
                  ))}
              </TreeItem>
            ))}
          </TreeView>
        )}

        <Divider />

        <Link to="/help">
          <ListItem button selected={isSelected("/help")}>
            <ListItemIcon>
              <ContactSupportIcon />
            </ListItemIcon>
            <ListItemText
              primary="Help/Support"
              primaryTypographyProps={{
                variant: "body2",
              }}
            ></ListItemText>
          </ListItem>
        </Link>
        <Divider />

        <ListSubheader inset>Reporting</ListSubheader>
        <Link to="/combined-stats">
          <ListItem button selected={isSelected("/combined-stats")}>
            <ListItemIcon>
              <QueryStatsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Combined Statistics"
              primaryTypographyProps={{
                variant: "body2",
              }}
            />
          </ListItem>
        </Link>
        {hasFeature("council") && (
          <Link to="/council-payments">
            <ListItem button selected={isSelected("/council-payments")}>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText
                primary="Council Payments"
                primaryTypographyProps={{
                  variant: "body2",
                }}
              />
            </ListItem>
          </Link>
        )}
        <Link to="/payments">
          <ListItem button selected={isSelected("/payments")}>
            <ListItemIcon>
              <LocalLibraryIcon />
            </ListItemIcon>
            <ListItemText
              primary="Library Payments"
              primaryTypographyProps={{
                variant: "body2",
              }}
            />
          </ListItem>
        </Link>
        {hasFeature("zreport") && (
          <Link to="/z-report">
            <ListItem button selected={isSelected("/z-report")}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primary="Z-Report"
                primaryTypographyProps={{
                  variant: "body2",
                }}
              />
            </ListItem>
          </Link>
        )}

        <Divider />

        {canAdmin() && (
          <>
            <ListSubheader inset>Administration</ListSubheader>
            <Link to="/users">
              <ListItem button selected={isSelected("/users")}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Users Management"
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
            </Link>
            <Link to="/sites">
              <ListItem button selected={isSelected("/sites")}>
                <ListItemIcon>
                  <LocationCityIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Sites Management"
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
            </Link>

            <Link to="/notifications">
              <ListItem button selected={isSelected("/notifications")}>
                <ListItemIcon>
                  <NotificationsActiveIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Notifications"
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
            </Link>

            <Link to="/email-server">
              <ListItem button selected={isSelected("/email-server")}>
                <ListItemIcon>
                  <ForwardToInbox />
                </ListItemIcon>
                <ListItemText
                  primary="Email Server"
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
            </Link>

            <Link to="/languages">
              <ListItem button selected={isSelected("/languages")}>
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Languages"
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
            </Link>

            <Link to="/activity-log">
              <ListItem button selected={isSelected("/activity-log")}>
                <ListItemIcon>
                  <Badge />
                </ListItemIcon>
                <ListItemText
                  primary="Activity Log"
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </ListItem>
            </Link>
            <Divider />
          </>
        )}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}
