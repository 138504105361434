import { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import { createSite } from "../../api";
import { getFormPayload } from "../../utils";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "auto 8px !important",
  },
  form: {
    marginTop: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(2) + " !important",
  },
}));

export default function Create() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    mutate: doCreateSite,
    isLoading,
    error,
  } = useMutation(createSite, {
    onSuccess: ({ message, site }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries(["sites"]);
      history.push(`/sites/${site.id}/kiosks`);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const data = getFormPayload(e.currentTarget);
      doCreateSite(data);
    },
    [doCreateSite]
  );

  const validationErrors = error && error.errors;

  return (
    <Layout>
      <Box display="flex">
        <Link to="/sites">
          <IconButton>
            <ArrowBack />
          </IconButton>
        </Link>
        <Typography variant="h5" className={classes.title}>
          Create new site
        </Typography>
      </Box>

      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          name="name"
          className={classes.input}
          label="Name"
          variant="outlined"
          fullWidth
          error={!!validationErrors?.name}
          helperText={validationErrors?.name}
        />

        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disabled={isLoading}
        >
          Submit
        </Button>
      </form>
    </Layout>
  );
}
