import { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ReplayIcon from "@material-ui/icons/Replay";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { updateUser, getUser } from "../../api";
import { getFormPayload } from "../../utils";
import { useParams } from "react-router";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "auto 8px !important",
  },
  form: {
    marginTop: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(2) + " !important",
  },
}));

export default function Edit() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { id } = useParams();
  const [user, setUser] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword, setShowPassword]
  );

  const generatePassword = useCallback(() => {
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let pwordLength = 20;
    let password = "";

    const array = new Uint32Array(chars.length);
    window.crypto.getRandomValues(array);

    for (let i = 0; i < pwordLength; i++) {
      password += chars[array[i] % chars.length];
    }

    setUser({
      ...user,
      password,
    });
  }, [setUser, user]);

  useQuery(`user-${id}`, getUser(id), {
    onSuccess: (user) => setUser(user),
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const {
    mutate: doUpdateUser,
    isLoading,
    error,
  } = useMutation(updateUser, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries(`user-${id}`);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const handleChange = (key) => (e) =>
    setUser({ ...user, [key]: e.target.value });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const user = getFormPayload(e.currentTarget);
      doUpdateUser({ id, user });
    },
    [id, doUpdateUser]
  );

  const validationErrors = error && error.errors;

  return (
    <Layout>
      <Box display="flex">
        <Link to="/users">
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography variant="h5" className={classes.title}>
          Edit user
        </Typography>
      </Box>

      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          name="name"
          className={classes.input}
          value={user?.name || ""}
          onChange={handleChange("name")}
          InputLabelProps={{ shrink: true }}
          label="Full name"
          required
          variant="outlined"
          fullWidth
          error={!!validationErrors?.name}
          helperText={validationErrors?.name}
        />
        <TextField
          name="email"
          value={user?.email || ""}
          onChange={handleChange("email")}
          InputLabelProps={{ shrink: true }}
          className={classes.input}
          label="Email"
          required
          variant="outlined"
          fullWidth
          error={!!validationErrors?.email}
          helperText={validationErrors?.email}
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          required
          fullWidth
          variant="outlined"
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          value={user?.password}
          onChange={handleChange("password")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePassword}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
                <IconButton onClick={generatePassword}>
                  <ReplayIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: 2 }}
        />

        <FormControl
          className={classes.input}
          error={!!validationErrors?.user_type}
          fullWidth
          required
        >
          <InputLabel id="user-type-label">User type</InputLabel>
          <Select
            name="user_type"
            labelId="user-type-label"
            value={user?.user_type || 100}
            onChange={handleChange("user_type")}
            id="user_type"
            label="User type"
          >
            <MenuItem value={400}>Admin</MenuItem>
            {/* <MenuItem value={300}>Staff control</MenuItem> */}
            <MenuItem value={100}>Viewer only</MenuItem>
          </Select>
          <FormHelperText>{validationErrors?.user_type}</FormHelperText>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disabled={isLoading}
        >
          Submit
        </Button>
      </form>
    </Layout>
  );
}
