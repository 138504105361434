import { Box, Chip } from "@material-ui/core";
import { Download, Upload } from "@material-ui/icons";
import {
  uploadSettings,
  downloadSettings,
  uploadTheme,
  downloadTheme,
  uploadLanguages,
  downloadLanguages,
} from "../../api";
import { saveAs } from "file-saver";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useRef } from "react";

const functions = {
  settings: [uploadSettings, downloadSettings],
  theme: [uploadTheme, downloadTheme],
  languages: [uploadLanguages, downloadLanguages],
};

export default function ImportExport({ type, metadata, cacheKey }) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const fileInput = useRef(null);

  const [uploadApiCall, downloadApiCall] = functions[type];

  const { mutate: doDownload } = useMutation(downloadApiCall, {
    onSuccess: ({ data }) => {
      saveAs(data, type + ".json");
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const { mutate: doUpload } = useMutation(uploadApiCall, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      cacheKey && queryClient.invalidateQueries(cacheKey);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const handleImport = () => {
    if (fileInput.current) fileInput.current.click();
  };

  const handleExport = () => doDownload({ ...metadata });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        try {
          const data = JSON.parse(reader.result);
          doUpload({ ...metadata, data });
        } catch (e) {
          enqueueSnackbar("Invalid file", { variant: "error" });
        }
        if (fileInput.current) fileInput.current.value = "";
      },
      false
    );
    if (file) reader.readAsText(file);
  };

  return (
    <Box display="flex" marginBottom={6}>
      <Chip
        color="primary"
        variant="outlined"
        style={{ marginLeft: "auto", marginRight: 8, cursor: "pointer" }}
        label="Import"
        onClick={handleImport}
        icon={<Upload />}
      />
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInput}
        accept="application/json"
        onChange={handleFileChange}
      />
      <Chip
        style={{ cursor: "pointer" }}
        color="primary"
        variant="outlined"
        label="Export"
        onClick={handleExport}
        icon={<Download />}
      />
    </Box>
  );
}
