import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Autocomplete,
  Chip,
  TextField,
  Grid,
  Alert,
  makeStyles,
} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { GetApp, Email, Print } from "@material-ui/icons";
import {
  getPayments,
  downloadPayments,
  emailPayments,
  printPayments,
  downloadTxPayments,
} from "../api";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import Layout from "../Layouts/AppLayout";
import DatePicker from "../Components/DatePicker";
import { last7DaysRange } from "../Components/DatePicker";
import { saveAs } from "file-saver";
import printJS from "print-js";
import { LocalizationProvider, TimePicker } from "@material-ui/lab";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { KioskSelector } from "../Components/KioskSelector";

const useStyles = makeStyles((theme) => ({}));

export default function Payments({ type, title }) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [payments, setPayments] = useState({});
  const [dateRange, setDateRange] = useState(last7DaysRange);
  const [startDate, endDate] = dateRange;

  const [startTime, setStartTime] = useState(moment().startOf("day"));
  const [endTime, setEndTime] = useState(moment().endOf("day"));

  const [selectedIds, setSelectedIds] = useState([]);

  const { isLoading } = useQuery(
    ["payments", type, startDate, endDate, startTime, endTime, selectedIds],
    getPayments(
      type,
      startDate,
      endDate,
      startTime?.format("HH:mm"),
      endTime?.format("HH:mm"),
      selectedIds
    ),
    {
      onSuccess: (payments) => setPayments(payments),
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const [emailOpen, setEmailOpen] = useState(false);
  const [emails, setEmails] = useState([]);

  const onEmailClose = () => {
    setEmails([]);
    setEmailOpen(false);
  };
  const onEmailOpen = () => {
    setEmails([]);
    setEmailOpen(true);
  };

  const onEmailsChange = (e, values) => setEmails(values);

  const { mutate: doEmail } = useMutation(emailPayments, {
    onSuccess: ({ message }) => {
      onEmailClose();
      enqueueSnackbar(message, { variant: "success" });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const onEmailSend = () => {
    doEmail({
      type,
      startDate,
      endDate,
      startTime: startTime?.format("HH:mm"),
      endTime: endTime?.format("HH:mm"),
      selectedIds,
      emails,
    });
  };

  const { mutate: doDownload } = useMutation(downloadPayments, {
    onSuccess: ({ data }) => {
      saveAs(data, `payments-${type}.csv`);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const onDownload = () => {
    doDownload({
      type,
      startDate,
      endDate,
      startTime: startTime?.format("HH:mm"),
      endTime: endTime?.format("HH:mm"),

      selectedIds,
    });
  };

  const { mutate: doPrint, isLoading: printLoading } = useMutation(
    printPayments,
    {
      onSuccess: ({ data }) => {
        printJS(URL.createObjectURL(data));
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const onPrint = () => {
    doPrint({
      type,
      startDate,
      endDate,
      startTime: startTime?.format("HH:mm"),
      endTime: endTime?.format("HH:mm"),
      selectedIds,
    });
  };

  const { mutate: doTxDownload } = useMutation(downloadTxPayments, {
    onSuccess: (data) => {
      saveAs(data, `transactions-${type}.csv`);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });
  const onTxDownload = () => {
    doTxDownload({
      type,
      startDate,
      endDate,
      startTime: startTime?.format("HH:mm"),
      endTime: endTime?.format("HH:mm"),
      selectedIds,
    });
  };

  return (
    <Layout>
      <Typography variant="h5" marginBottom={2}>
        {title}
      </Typography>

      <Grid container spacing={3} marginTop={4}>
        <Grid item xs={12} md={2}>
          <KioskSelector onChange={(ids) => setSelectedIds(ids)} />
        </Grid>
        <Grid item xs={12} md={10}>
          <DatePicker
            onChange={setDateRange}
            element={
              <>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    label="Start time"
                    value={startTime}
                    onChange={setStartTime}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText=""
                        style={{ maxWidth: 140, marginLeft: 16, bottom: 3 }}
                      />
                    )}
                  />
                  <TimePicker
                    label="End time"
                    value={endTime}
                    onChange={setEndTime}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        helperText=""
                        style={{ maxWidth: 140, marginLeft: 8, bottom: 3 }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <Button
                  startIcon={<Email />}
                  onClick={onEmailOpen}
                  style={{ marginLeft: "auto" }}
                >
                  Email
                </Button>
                <Dialog
                  open={emailOpen}
                  onClose={onEmailClose}
                  aria-labelledby="email-dialog-title"
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle id="email-dialog-title">
                    Send report via email
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <Alert severity="info" style={{ marginBottom: 16 }}>
                        Please enter email addresses, press Enter after each
                        email.
                      </Alert>
                    </DialogContentText>
                    <Autocomplete
                      multiple
                      freeSolo
                      options={[]}
                      onChange={onEmailsChange}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Email address" />
                      )}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onEmailClose}>Cancel</Button>
                    <Button onClick={onEmailSend}>Send</Button>
                  </DialogActions>
                </Dialog>

                <LoadingButton
                  startIcon={<Print />}
                  onClick={onPrint}
                  style={{ marginLeft: 4 }}
                  loading={printLoading}
                  loadingPosition="start"
                >
                  Print
                </LoadingButton>
                <Button
                  startIcon={<GetApp />}
                  onClick={onDownload}
                  style={{ marginLeft: 4 }}
                >
                  Export
                </Button>
                <Button
                  startIcon={<GetApp />}
                  onClick={onTxDownload}
                  style={{ marginLeft: 4 }}
                >
                  Transactions
                </Button>
              </>
            }
          />

          {isLoading ? (
            <Box display="flex">
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Site</TableCell>
                    <TableCell>Kiosk</TableCell>
                    <TableCell>Cash</TableCell>
                    <TableCell>Card</TableCell>
                    <TableCell>Change given</TableCell>
                    <TableCell>Total takings</TableCell>
                    <TableCell>Coin float (Last updated)</TableCell>
                    <TableCell>Notes (Last updated)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments && payments.length > 0 ? (
                    payments.map((payment, i) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          {payment.site}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {payment.kiosk}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {payment.cash || "0.00"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {payment.card || "0.00"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {payment.change || "0.00"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {payment.total || "0.00"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {payment.float && `${payment.float} `}
                          {payment.float_reset_at &&
                            `(${payment.float_reset_at})`}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {payment.notes && `${payment.notes} `}
                          {payment.notes_reset_at &&
                            `(${payment.notes_reset_at})`}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No payments found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}
