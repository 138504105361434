import { makeStyles } from "@material-ui/core/styles";
import { Container, CssBaseline, Typography } from "@material-ui/core";
import logo from "../../res/manageit-logo-blue.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#851400",
  },
  logo: {
    marginBottom: theme.spacing(8),
  },
  "@global": {
    body: {
      backgroundColor: "#ff9e8d !important",
    },
  },
}));

export default function LicenseExpired() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          alt="manageIT logo"
          width={160}
          src={logo}
          className={classes.logo}
        />

        <Typography variant="h6">
          The license for this location is invalid, please contact D-Tech to
          renew your license.
        </Typography>
      </div>
    </Container>
  );
}
