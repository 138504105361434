import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

export function RouterPrompt(props) {
  const { when, title, body } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const unblockRef = useRef();

  function handleShowModal() {
    setShowPrompt(true);
  }

  function onCancel() {
    setShowPrompt(false);
  }

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when) {
        setCurrentPath(location.pathname);
        handleShowModal();
        return false;
      }
      return true;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when, history]);

  function handleConfirm() {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  }

  return showPrompt ? (
    <Dialog open={showPrompt} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 16 }}>
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button type="submit" color="secondary" onClick={handleConfirm}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
