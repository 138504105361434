import LoadingButton from "@material-ui/lab/LoadingButton";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Container, TextField } from "@material-ui/core";
import logo from "../../res/manageit-logo-blue.svg";
import dtechLogo from "../../res/logo.png";
import { useMutation } from "react-query";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { getFormPayload } from "../../utils";
import { forgot } from "../../api";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginBottom: theme.spacing(1),
  },
  alert: {
    width: "100%",
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  line: {
    marginTop: 30,
    width: "100%",
    border: "none",
    borderBottom: "1px solid #c4c4c4",
  },
}));

export default function Forgot() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: doForgot,
    isLoading,
    error,
  } = useMutation(forgot, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const sendForgot = (e) => {
    e.preventDefault();
    const credentials = getFormPayload(e.currentTarget);
    doForgot(credentials);
  };

  const validationErrors = error && error.errors;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          alt="manageIT logo"
          width={160}
          src={logo}
          className={classes.logo}
        />

        <form className={classes.form} onSubmit={sendForgot} noValidate>
          <Typography variant="body2">
            Please enter your email, an link will be sent to you to reset the
            password.
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            autoFocus
            error={!!validationErrors?.email}
            helperText={validationErrors?.email}
          />
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send request
          </LoadingButton>
        </form>
        <hr className={classes.line} />
        <img src={dtechLogo} width={80} alt="D-Tech logo" />
        <Typography variant="caption">© D-Tech International Ltd</Typography>
      </div>
    </Container>
  );
}
