import { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getLanguageScreen, updateLanguageScreen } from "../../api";
import { useSnackbar } from "notistack";
import { getFormPayload } from "../../utils";
import { useParams } from "react-router";
import { DirAutoTextInput } from "../../Components/DirAutoTextInput";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(4),
  },
  createButton: {
    marginLeft: "auto !important",
  },
  progress: {
    margin: "100px auto !important",
  },
}));

export default function ServeITEdit() {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [screen, setScreen] = useState("main");
  const [languageScreen, setLanguageScreen] = useState([]);

  const { isLoading } = useQuery(
    ["languages", id, screen],
    getLanguageScreen(id, screen),
    {
      onSuccess: (data) => setLanguageScreen(data),
      onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
    }
  );

  const { mutate: doUpdateLanguageScreen, isLoading: isPostLoading } =
    useMutation(updateLanguageScreen, {
      onSuccess: ({ message }) => {
        enqueueSnackbar(message, { variant: "success" });
        queryClient.invalidateQueries(["languages", id, screen]);
        setNewFieldIds([]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const rows = getFormPayload(e.currentTarget);
      doUpdateLanguageScreen({ languageId: id, screen, rows });
    },
    [id, screen, doUpdateLanguageScreen]
  );

  const [newFieldIds, setNewFieldIds] = useState([]);
  const addNewField = () => {
    setNewFieldIds([...newFieldIds, ""]);
  };
  const onChangeId = (i) => (e) => {
    setNewFieldIds(
      newFieldIds.map((ee, ii) => (ii === i ? e.target.value : ee))
    );
  };

  return (
    <Layout>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Box display="flex">
          <Typography variant="h5">Edit language</Typography>

          <FormControl style={{ marginLeft: "auto" }}>
            <Select
              id="select"
              value={screen}
              onChange={(e) => setScreen(e.target.value)}
            >
              <MenuItem value="main">Main screen</MenuItem>
              <MenuItem value="login">Login screen</MenuItem>
              <MenuItem value="pin">PIN screen</MenuItem>
              <MenuItem value="borrow">Borrow screen</MenuItem>
              <MenuItem value="return">Return screen</MenuItem>
              <MenuItem value="renew">Renew screen</MenuItem>
              <MenuItem value="reservation">Reservation screen</MenuItem>
              <MenuItem value="cash-payment">Cash payment screen</MenuItem>
              <MenuItem value="card-payment">Card payment screen</MenuItem>
              <MenuItem value="help-messages">Help messages</MenuItem>
            </Select>
          </FormControl>
          <Button
            style={{ marginLeft: 16 }}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            disabled={isPostLoading}
          >
            Submit
          </Button>
        </Box>

        <Alert severity="warning" style={{ marginBottom: 16, marginTop: 16 }}>
          If you change the language the Kiosk application must to be restarted
          in order for the new definitons to load.
        </Alert>

        {isLoading ? (
          <Box display="flex">
            <CircularProgress className={classes.progress} />
          </Box>
        ) : (
          <>
            <TableContainer component={Paper} className={classes.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width={200}>
                      ID
                    </TableCell>
                    <TableCell align="left" width={300}>
                      English
                    </TableCell>
                    <TableCell align="left">Translation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {languageScreen && languageScreen.length > 0 ? (
                    languageScreen.map((row, i) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="left">{row.english}</TableCell>
                        <TableCell align="left">
                          <DirAutoTextInput
                            variant="standard"
                            fullWidth
                            name={`rows[${row.id}]`}
                            multiline
                            style={{
                              height: "auto",
                              maxHeight: "100px",
                              overflow: "auto",
                            }}
                            defaultValue={row.translation}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No translations found
                      </TableCell>
                    </TableRow>
                  )}

                  {newFieldIds?.map((newFieldId, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        <TextField
                          variant="standard"
                          fullWidth
                          multiline
                          style={{
                            height: "auto",
                            maxHeight: "100px",
                            overflow: "auto",
                          }}
                          value={newFieldId}
                          onChange={onChangeId(i)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          variant="standard"
                          fullWidth
                          name={`english[${newFieldId}]`}
                          multiline
                          style={{
                            height: "auto",
                            maxHeight: "100px",
                            overflow: "auto",
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <DirAutoTextInput
                          variant="standard"
                          fullWidth
                          name={`rows[${newFieldId}]`}
                          multiline
                          style={{
                            height: "auto",
                            maxHeight: "100px",
                            overflow: "auto",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginTop: 16 }}
              onClick={addNewField}
            >
              Add
            </Button>
          </>
        )}
      </form>
    </Layout>
  );
}
