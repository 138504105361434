import { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Delete, Add, Download, Upload, Edit } from "@material-ui/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteLanguage,
  addLanguage,
  getLanguages,
  getAvailableLanguages,
  uploadLanguage,
  downloadLanguage,
} from "../../api";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { getFormPayload } from "../../utils";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import ImportExport from "../Settings/ImportExport";
import KioskType from "../../Components/KioskType";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(4),
  },
  importExportBox: {
    marginLeft: "auto !important",
  },
  createButton: {
    marginLeft: theme.spacing(4),
    alignSelf: "flex-start",
  },
  progress: {
    margin: "100px auto !important",
  },
}));

export default function Languages() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const { isLoading, data: languages } = useQuery(
    ["languages"],
    getLanguages(),
    {
      onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
    }
  );
  const { data: availableLanguages } = useQuery(
    "available-languages",
    getAvailableLanguages(),
    {
      onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
    }
  );

  const [addOpen, setAddOpen] = useState(false);
  const onAddClose = () => setAddOpen(false);
  const onAddOpen = () => setAddOpen(true);
  const {
    mutate: doAddLanguage,
    isLoading: isAddLoading,
    error: addError,
  } = useMutation(addLanguage, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries(["languages"]);
      setAddOpen(false);
    },
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const language = getFormPayload(e.currentTarget);
      doAddLanguage(language);
    },
    [doAddLanguage]
  );

  const validationAddErrors = addError && addError.errors;

  const { mutate: doDeleteLanguage } = useMutation(deleteLanguage, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries(["languages"]);
    },
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });
  const onDelete = (id) => async () => {
    try {
      await confirm({
        title: "Delete language",
        description:
          "Are you sure you want to delete this language, this action is permanent",
        confirmationText: "delete",
      });
      doDeleteLanguage(id);
    } catch (e) {}
  };

  const openFileSelector = (id) => (e) => {
    document.getElementById(id).click();
  };

  const fileChanged = (languageId) => async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    const { data } = await uploadLanguage(languageId, formData);
    if (data.success) {
      queryClient.invalidateQueries(["languages"]);
      enqueueSnackbar("Uploaded the file successfully", { variant: "success" });
    }
    document.getElementById(`file-${languageId}`).value = "";
  };

  const { mutate: doDownload } = useMutation(downloadLanguage, {
    onSuccess: ({ data }) => {
      saveAs(data, "Template.xlsx");
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });
  const handleExport = (languageId) => (e) => doDownload({ languageId });

  return (
    <Layout>
      <Box display="flex">
        <Typography variant="h5">Languages</Typography>

        <div className={classes.importExportBox}>
          <ImportExport
            metadata={{}}
            cacheKey={["languages"]}
            type="languages"
          />
        </div>
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={onAddOpen}
        >
          Add
        </Button>

        <Dialog
          open={addOpen}
          onClose={onAddClose}
          aria-labelledby="add-language"
          maxWidth="sm"
          fullWidth
        >
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle id="add-language">Add language</DialogTitle>
            <DialogContent>
              <DialogContentText style={{ marginBottom: 16 }}>
                Select language code and enter a user friendly name.
              </DialogContentText>
              <FormControl
                variant="outlined"
                error={!!validationAddErrors?.name}
                fullWidth
                margin="dense"
              >
                <InputLabel id="name-label">Name</InputLabel>
                <Select
                  name="name"
                  labelId="name-label"
                  label="Name"
                  defaultValue=""
                >
                  {availableLanguages &&
                    availableLanguages.map((language, i) => (
                      <MenuItem key={i} value={language}>
                        {language}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{validationAddErrors?.name}</FormHelperText>
              </FormControl>
              <FormControl
                variant="outlined"
                error={!!validationAddErrors?.friendly_name}
                fullWidth
                margin="dense"
              >
                <TextField name="friendly_name" label="Friendly name" />
                <FormHelperText>
                  {validationAddErrors?.friendly_name}
                </FormHelperText>
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel id="type-label">Kiosk type</InputLabel>
                <Select
                  name="type"
                  className={classes.input}
                  label="Kiosk type"
                  labelId="type-label"
                  variant="outlined"
                  fullWidth
                  error={!!validationAddErrors?.type}
                  helperText={validationAddErrors?.type}
                >
                  <MenuItem value={0}>serveIT</MenuItem>
                  <MenuItem value={1}>holdIT</MenuItem>
                  <MenuItem value={2}>computeIT</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={onAddClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isAddLoading}>
                Add
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>

      {isLoading ? (
        <Box display="flex">
          <CircularProgress className={classes.progress} />
        </Box>
      ) : (
        <TableContainer component={Paper} className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Friendly name</TableCell>
                <TableCell align="left">Kiosk type</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {languages && languages.length > 0 ? (
                languages.map((language) => (
                  <TableRow key={language.id}>
                    <TableCell component="th" scope="row">
                      {language.name}
                    </TableCell>
                    <TableCell align="left">{language.friendly_name}</TableCell>
                    <TableCell align="left">
                      <KioskType type={language.type_str} />
                    </TableCell>
                    <TableCell align="left">
                      <Link
                        to={`/languages/${language.id}/${language.type_str}/edit`}
                      >
                        <IconButton title="Edit">
                          <Edit />
                        </IconButton>
                      </Link>
                      <IconButton
                        title="Download"
                        onClick={handleExport(language.id)}
                      >
                        <Download />
                      </IconButton>
                      <IconButton
                        title="Upload"
                        onClick={openFileSelector(`file-${language.id}`)}
                      >
                        <Upload />
                      </IconButton>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        id={`file-${language.id}`}
                        onChange={fileChanged(language.id)}
                      />

                      <IconButton
                        aria-label="delete"
                        title="Delete"
                        color="secondary"
                        onClick={onDelete(language.id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No languages found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Layout>
  );
}
