import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, Tooltip } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  helpTooltip: { cursor: "pointer" },
}));

export function Help({ className = "", text }) {
  const classes = useStyles();

  return text ? (
    <Tooltip title={text} className={className}>
      <HelpOutline className={classes.helpTooltip} />
    </Tooltip>
  ) : null;
}

export function HelpInput(props) {
  return (
    <InputAdornment position="start" style={props?.style}>
      <Help {...props} />
    </InputAdornment>
  );
}
