import axios from "axios";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + window.localStorage.getItem("token");

export class ValidationError extends Error {
  errors;

  constructor(errors = null, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }
    this.name = "ValidationError";
    this.errors = errors;
  }
}

async function request(promise) {
  try {
    const { data } = await promise;
    return data;
  } catch (e) {
    let {
      response: { data, status },
    } = e;

    if (data instanceof Blob) {
      data = await data.text();
      data = JSON.parse(data);
    }

    if (data.code === "license_invalid") {
      window.location.href = "/license-expired";
      return;
    }

    if (window.localStorage.getItem("token") && status === 401) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("tfa_token");

      window.location = "/login";
    }

    if (data.errors) {
      Object.keys(data.errors).forEach((key) => {
        data.errors[key] = data.errors[key][0];
      });
      throw new ValidationError(data.errors, data.message);
    }
    throw new Error(data.error);
  }
}

export function getMe() {
  return request(axios.get(`/api/me`));
}

export function login(payload) {
  return request(axios.post(`/api/login`, payload));
}

export function tfa(payload) {
  return request(axios.post(`/api/tfa`, payload));
}

export function logout() {
  return request(axios.post(`/api/logout`));
}

export function forgot(payload) {
  return request(axios.post(`/api/forgot`, payload));
}

export function reset(payload) {
  return request(axios.post(`/api/reset`, payload));
}

export function getUsers() {
  return () => request(axios.get("/api/users"));
}

export function getUser(id) {
  return () => request(axios.get(`/api/users/${id}`));
}

export function createUser(user) {
  return request(axios.post(`/api/users`, user));
}

export function updateUser({ id, user }) {
  return request(axios.put(`/api/users/${id}`, user));
}

export function deleteUser(id) {
  return request(axios.delete(`/api/users/${id}`));
}

// Sites

export function getSitesTree(type = null) {
  const typeParam = type ? `?type=${type}` : "";
  return () => request(axios.get(`/api/sites-tree${typeParam}`));
}

export function getSites() {
  return () => request(axios.get("/api/sites"));
}

export function getSite(id) {
  return () => request(axios.get(`/api/sites/${id}`));
}

export function createSite(site) {
  return request(axios.post(`/api/sites`, site));
}

export function updateSite({ id, site }) {
  return request(axios.put(`/api/sites/${id}`, site));
}

export function deleteSite(id) {
  return request(axios.delete(`/api/sites/${id}`));
}

// Kiosks

export function getAllKiosks() {
  return () => request(axios.get(`/api/kiosks`));
}

export function downloadAllkiosks() {
  return axios.get("/api/kiosks/download", { responseType: "blob" });
}

export function getKiosks(siteId) {
  return () => request(axios.get(`/api/sites/${siteId}/kiosks`));
}

export function getKiosk(siteId, id) {
  return () => request(axios.get(`/api/sites/${siteId}/kiosks/${id}`));
}

export function createKiosk({ siteId, kiosk }) {
  return request(axios.post(`/api/sites/${siteId}/kiosks`, kiosk));
}

export function updateKiosk({ siteId, kioskId, kiosk }) {
  return request(axios.put(`/api/sites/${siteId}/kiosks/${kioskId}`, kiosk));
}

export function deleteKiosk({ siteId, id }) {
  return request(axios.delete(`/api/sites/${siteId}/kiosks/${id}`));
}

// Counts
export function getCounts(siteId, id, startDate, endDate) {
  return () =>
    request(
      axios.get(
        `/api/sites/${siteId}/kiosks/${id}/counts?start_date=${startDate}&end_date=${endDate}`
      )
    );
}

export function downloadCounts(siteId, id, startDate, endDate) {
  return axios.get(
    `/api/sites/${siteId}/kiosks/${id}/download-counts?start_date=${startDate}&end_date=${endDate}`,
    { responseType: "blob" }
  );
}

export function getCombinedCounts(ids, startDate, endDate) {
  return () =>
    request(
      axios.get(
        `/api/combined-counts?ids=${ids.join(
          ","
        )}&start_date=${startDate}&end_date=${endDate}`
      )
    );
}

export function downloadCombinedCountsByDate({ ids, startDate, endDate }) {
  return axios.get(
    `/api/download-combined-counts/date?ids=${ids.join(
      ","
    )}&start_date=${startDate}&end_date=${endDate}`,
    { responseType: "blob" }
  );
}

export function downloadCombinedCountsByKiosk({ ids, startDate, endDate }) {
  return axios.get(
    `/api/download-combined-counts/kiosk?ids=${ids.join(
      ","
    )}&start_date=${startDate}&end_date=${endDate}`,
    { responseType: "blob" }
  );
}

// Payments
export function getPayments(
  type,
  startDate,
  endDate,
  startTime = null,
  endTime = null,
  selectedIds = null
) {
  return () =>
    request(
      axios.get(
        `/api/payments/${type}?start_date=${startDate}&end_date=${endDate}${
          startTime ? "&start_time=" + startTime : ""
        }${endTime ? "&end_time=" + endTime : ""}${
          selectedIds && selectedIds.length > 0
            ? "&selectedIds=" + selectedIds.join(",")
            : ""
        }`
      )
    );
}

export function downloadPayments({
  type,
  startDate,
  endDate,
  startTime = null,
  endTime = null,
  selectedIds = null,
}) {
  return axios.get(
    `/api/download-payments/${type}?start_date=${startDate}&end_date=${endDate}${
      startTime ? "&start_time=" + startTime : ""
    }${endTime ? "&end_time=" + endTime : ""}${
      selectedIds && selectedIds.length > 0
        ? "&selectedIds=" + selectedIds.join(",")
        : ""
    }`,
    { responseType: "blob" }
  );
}

export function downloadTxPayments({
  type,
  startDate,
  endDate,
  startTime = null,
  endTime = null,
  selectedIds = null,
}) {
  return request(
    axios.get(
      `/api/download-transactions/${type}?start_date=${startDate}&end_date=${endDate}${
        startTime ? "&start_time=" + startTime : ""
      }${endTime ? "&end_time=" + endTime : ""}${
        selectedIds && selectedIds.length > 0
          ? "&selectedIds=" + selectedIds.join(",")
          : ""
      }`,
      { responseType: "blob" }
    )
  );
}

export function emailPayments({
  type,
  startDate,
  endDate,
  emails,
  startTime = null,
  endTime = null,
  selectedIds = null,
}) {
  return request(
    axios.post(
      `/api/email-payments/${type}?start_date=${startDate}&end_date=${endDate}${
        startTime ? "&start_time=" + startTime : ""
      }${endTime ? "&end_time=" + endTime : ""}${
        selectedIds && selectedIds.length > 0
          ? "&selectedIds=" + selectedIds.join(",")
          : ""
      }`,
      { emails }
    )
  );
}

export function printPayments({
  type,
  startDate,
  endDate,
  startTime = null,
  endTime = null,
  selectedIds = null,
}) {
  return axios.get(
    `/api/print-payments/${type}?start_date=${startDate}&end_date=${endDate}${
      startTime ? "&start_time=" + startTime : ""
    }${endTime ? "&end_time=" + endTime : ""}${
      selectedIds && selectedIds.length > 0
        ? "&selectedIds=" + selectedIds.join(",")
        : ""
    }`,
    { responseType: "blob" }
  );
}

// Z report
export function getZReport(selectedIds, includeLibrary, includeCouncil) {
  return () =>
    request(
      axios.get(
        `/api/z-report?selectedIds=${selectedIds}&includeLibrary=${includeLibrary}&includeCouncil=${includeCouncil}`
      )
    );
}

export function printZReport({ selectedIds, includeLibrary, includeCouncil }) {
  return axios.get(
    `/api/print-z-report?selectedIds=${selectedIds}&includeLibrary=${includeLibrary}&includeCouncil=${includeCouncil}`,
    {
      responseType: "blob",
    }
  );
}

export function updateZDate({ selectedIds }) {
  return request(axios.put(`/api/z-report?selectedIds=${selectedIds}`));
}

export function emailZReport({
  selectedIds,
  emails,
  includeLibrary,
  includeCouncil,
}) {
  return request(
    axios.post(
      `/api/z-report/email?selectedIds=${selectedIds}&includeLibrary=${includeLibrary}&includeCouncil=${includeCouncil}`,
      { emails }
    )
  );
}

export function downloadZReport({
  selectedIds,
  emails,
  includeLibrary,
  includeCouncil,
}) {
  return axios.post(
    `/api/z-report/download?selectedIds=${selectedIds}&includeLibrary=${includeLibrary}&includeCouncil=${includeCouncil}`,
    { emails },
    { responseType: "blob" }
  );
}

// Home
export function getHomeData() {
  return () => request(axios.get(`/api/home`));
}

export function getLiveUpdates() {
  return () => request(axios.get(`/api/live-updates`));
}

export function dismiss(id) {
  return request(axios.put(`/api/live-updates/${id}/dismiss`));
}
export function markRead(id) {
  return request(axios.put(`/api/live-updates/${id}/read`));
}
export function dismissAll() {
  return request(axios.put(`/api/live-updates/dismiss-all`));
}
export function markReadAll() {
  return request(axios.put(`/api/live-updates/read-all`));
}

// Settings
export function getSettings(siteId, kioskId, tab, smartType = null) {
  return () => {
    const smartLink = smartType ? "/smart/" + smartType : "";

    return request(
      axios.get(
        `/api/sites/${siteId}/kiosks/${kioskId}${smartLink}/settings/${tab}`
      )
    );
  };
}

export function updateSettings({
  siteId,
  kioskId,
  tab,
  saveOption,
  selectedIds,
  settings,
  smartType,
}) {
  const smartLink = smartType ? "/smart/" + smartType : "";

  return request(
    axios.put(
      `/api/sites/${siteId}/kiosks/${kioskId}${smartLink}/settings/${tab}?saveOption=${saveOption}&selectedIds=${selectedIds}`,
      settings
    )
  );
}

export function downloadSettings({ siteId, kioskId }) {
  return axios.get(`/api/sites/${siteId}/kiosks/${kioskId}/download-settings`, {
    responseType: "blob",
  });
}

export function uploadSettings({ siteId, kioskId, data }) {
  return request(
    axios.put(`/api/sites/${siteId}/kiosks/${kioskId}/upload-settings`, data)
  );
}

export function uploadImage(formData) {
  return axios.post(`/api/assets`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// Theme
export function getTheme(siteId, kioskId, tab, subTab, smartType = null) {
  return () => {
    const smartLink = smartType ? "/smart/" + smartType : "";

    return request(
      axios.get(
        `/api/sites/${siteId}/kiosks/${kioskId}${smartLink}/theme/${tab}/${subTab}`
      )
    );
  };
}

export function updateTheme({
  siteId,
  kioskId,
  tab,
  subTab,
  saveOption,
  selectedIds,
  theme,
  smartType,
}) {
  const smartLink = smartType ? "/smart/" + smartType : "";

  return request(
    axios.put(
      `/api/sites/${siteId}/kiosks/${kioskId}${smartLink}/theme/${tab}/${subTab}?saveOption=${saveOption}&selectedIds=${selectedIds}`,
      theme
    )
  );
}

export function downloadTheme({ siteId, kioskId }) {
  return axios.get(`/api/sites/${siteId}/kiosks/${kioskId}/download-theme`, {
    responseType: "blob",
  });
}

export function uploadTheme({ siteId, kioskId, data }) {
  return request(
    axios.put(`/api/sites/${siteId}/kiosks/${kioskId}/upload-theme`, data)
  );
}

// Languages

export function getLanguages() {
  return () => request(axios.get(`/api/languages`));
}

export function getKioskLanguages(kioskType) {
  return () => request(axios.get(`/api/languages?type=${kioskType}`));
}

export function getLanguageScreen(languageId, screen) {
  return () =>
    request(axios.get(`/api/languages/${languageId}/translations/${screen}`));
}

export function updateLanguageScreen({ languageId, screen, rows }) {
  return request(
    axios.put(`/api/languages/${languageId}/translations/${screen}`, rows)
  );
}

export function downloadLanguage({ languageId }) {
  return axios.get(`/api/languages/${languageId}/download`, {
    responseType: "blob",
  });
}

export function uploadLanguage(languageId, formData) {
  return axios.post(`/api/languages/${languageId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getAvailableLanguages() {
  return () => request(axios.get(`/api/available-languages`));
}

export function addLanguage(language) {
  return request(axios.post(`/api/languages`, language));
}

export function deleteLanguage(id) {
  return request(axios.delete(`/api/languages/${id}`));
}

export function downloadLanguages() {
  return axios.get(`/api/languages/download`, { responseType: "blob" });
}

export function uploadLanguages({ data }) {
  return request(axios.post(`/api/languages/upload`, { languages: data }));
}

export function getTooltips() {
  return () => request(axios.get(`/api/tooltips`));
}

export function getNotificationSettings(siteId) {
  return () =>
    request(axios.get(`/api/global-settings/notifications/${siteId}`));
}

export function updateNotificationSettings({ siteId, settings }) {
  return request(
    axios.put(`/api/global-settings/notifications/${siteId}`, settings)
  );
}

export function getEmailServerSettings() {
  return () => request(axios.get(`/api/global-settings/email-server`));
}

export function updateEmailServerSettings(data) {
  return request(axios.put(`/api/global-settings/email-server`, data));
}

export function getActivityLog(site) {
  return () => request(axios.get(`/api/activity-log?site=${site}`));
}
