export const getToken = () => window.localStorage.getItem("token");
export const getTFAToken = () => window.localStorage.getItem("tfa_token");
export const getUser = (): {
  name: string;
  user_type: number;
  features?: string[];
} =>
  window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user") || "")
    : null;

export const canAdmin = () => {
  const user = getUser();
  return [400, 500].includes(user?.user_type);
};

export const isSuperAdmin = () => {
  const user = getUser();
  return user?.user_type === 500;
};

export const hasFeature = (feature: string) => {
  const user = getUser();
  return user?.features?.includes(feature);
};

export const IsLoggedIn = (to: any, from: any, next: any) => {
  if (to.meta.auth) {
    if (getToken()) next();
    next.redirect("/login");
  } else next();
};

export const IsNotLoggedIn = (to: any, from: any, next: any) => {
  if (getToken()) next.redirect("/");
  else next();
};

export const hasTFAToken = (to: any, from: any, next: any) => {
  if (!getTFAToken()) next.redirect("/");
  else next();
};

export const isAdmin = (to: any, from: any, next: any) => {
  if (!canAdmin()) next.redirect("/");
  else next();
};
