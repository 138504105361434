import { useCallback, useMemo, useState } from "react";
import { Box, Chip } from "@material-ui/core";
import { Today, Close } from "@material-ui/icons";
import { DateRangePicker } from "materialui-daterange-picker";
import moment from "moment";

export const last7Days = {
  label: "Last 7 days",
  startDate: moment().subtract(7, "days").toDate(),
  endDate: moment().toDate(),
};

export const last7DaysRange = [
  moment(last7Days.startDate).format("DD-MM-YYYY"),
  moment(last7Days.endDate).format("DD-MM-YYYY"),
];

export default function DatePicker({ onChange, element }) {
  const [dateRange, setDateRange] = useState(last7Days);
  const [open, setOpen] = useState(false);

  const startDate = useMemo(
    () => moment(dateRange.startDate).format("DD-MM-YYYY"),
    [dateRange.startDate]
  );

  const endDate = useMemo(
    () => moment(dateRange.endDate).format("DD-MM-YYYY"),
    [dateRange.endDate]
  );

  const resetDate = useCallback(() => {
    onChange(last7DaysRange);
    setDateRange(last7Days);
  }, [onChange, setDateRange]);

  const toggle = useCallback(() => setOpen(!open), [setOpen, open]);
  const showPicker = useCallback(() => setOpen(true), [setOpen]);

  return (
    <Box position="relative" marginBottom={4}>
      <Box display="flex">
        <Chip
          icon={<Today />}
          label={dateRange.label || `${startDate} ➜ ${endDate}`}
          color="primary"
          deleteIcon={<Close />}
          onDelete={resetDate}
          onClick={showPicker}
        />
        {element || null}
      </Box>
      <Box position="absolute">
        <DateRangePicker
          closeOnClickOutside={true}
          initialDateRange={dateRange}
          open={open}
          toggle={toggle}
          onChange={(range) => {
            onChange([
              moment(range.startDate).format("DD-MM-YYYY"),
              moment(range.endDate).format("DD-MM-YYYY"),
            ]);
            setDateRange(range);
          }}
        />
      </Box>
    </Box>
  );
}
