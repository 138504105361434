import { makeStyles } from "@material-ui/core/styles";
import { useMemo, useState } from "react";
import { getUser } from "../auth";
import { Link, useHistory } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getLiveUpdates,
  logout,
  dismissAll,
  dismiss,
  markRead,
  markReadAll,
} from "../api";
import {
  Notifications,
  AccountCircle,
  Logout,
  Delete,
  Check,
} from "@material-ui/icons";
import {
  Badge,
  Divider,
  IconButton,
  Typography,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  AppBar as AppBarMUI,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@material-ui/core";
import moment from "moment";
import manageITLogo from "../res/manageit-logo.svg";

const useStyles = makeStyles((theme) => ({
  logo: {
    color: "#fff",
    textDecoration: "none !important",
    lineHeight: 1,
    position: "relative",
    top: 2,
  },
  accountButtonLabel: {
    textTransform: "none",
  },
  notificationIcon: {
    marginLeft: "auto !important",
    marginRight: theme.spacing(2),
  },
  notificationMenu: {
    width: 320,
    display: "block",
    whiteSpace: "normal",
  },
  newNotification: {
    background: "#e4e7ee",
    "&:hover": {
      background: "#c4ccdf",
    },
    "& h6": {
      fontWeight: 700,
    },
  },
  dismissBtn: {
    width: "100%",
  },
  accountMenu: {
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiListItem-root": {
      ...theme.typography.body2,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 18,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1.5),
    },
  },
}));

export default function AppBar() {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [accountAnchor, setAccountAnchor] = useState(null);
  const openAccount = (e) => setAccountAnchor(e.currentTarget);
  const closeAccount = () => setAccountAnchor(null);

  const [notificationAnchor, setNotificationAnchor] = useState(null);
  const openNotification = (e) => setNotificationAnchor(e.currentTarget);
  const closeNotification = () => setNotificationAnchor(null);

  const { data: liveUpdates = [] } = useQuery(
    "live-updates",
    getLiveUpdates(),
    {
      staleTime: 300000,
    }
  );

  const { mutate: doDismissAll } = useMutation(dismissAll, {
    onSuccess: () => {
      queryClient.invalidateQueries("live-updates");
      closeNotification();
    },
  });
  const onDismissAll = (e) => doDismissAll();

  const { mutate: doMarkReadAll } = useMutation(markReadAll, {
    onSuccess: () => {
      queryClient.invalidateQueries("live-updates");
      closeNotification();
    },
  });
  const onMarkReadAll = (e) => doMarkReadAll();

  const { mutate: doDismiss } = useMutation(dismiss, {
    onSuccess: () => {
      queryClient.invalidateQueries("live-updates");
      closeNotification();
    },
  });
  const onDismiss = (id) => (e) => {
    e.stopPropagation();
    doDismiss(id);
  };

  const { mutate: doMarkRead } = useMutation(markRead, {
    onSuccess: () => {
      queryClient.invalidateQueries("live-updates");
      closeNotification();
    },
  });
  const onMarkRead = (id) => (e) => {
    e.stopPropagation();
    doMarkRead(id);
  };

  const { mutate: doLogout } = useMutation(logout, {
    onSettled: () => {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      history.go("/login");
    },
  });
  const handleLogout = doLogout;

  const [notification, setNotification] = useState(null);
  const onNotificationClose = () => setNotification(null);
  const openNotificationModal = (update) => {
    closeNotification();
    setNotification(update);
  };

  const unreadLiveUpdates = useMemo(() => {
    return liveUpdates.filter((e) => !e.read).length;
  }, [liveUpdates]);

  return (
    <AppBarMUI position="fixed">
      <Toolbar>
        <Link to="/" className={classes.logo}>
          <img src={manageITLogo} height={32} alt="ManageIT logo" />
        </Link>
        <IconButton
          aria-label="show 3 new notifications"
          color="inherit"
          className={classes.notificationIcon}
          onClick={liveUpdates.length ? openNotification : null}
        >
          <Badge badgeContent={unreadLiveUpdates} color="secondary">
            <Notifications />
          </Badge>
        </IconButton>
        <Menu
          id="notification-appbar"
          anchorEl={notificationAnchor}
          keepMounted
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(notificationAnchor)}
          onClose={closeNotification}
          classes={{
            list: classes.notificationMenu,
          }}
        >
          {liveUpdates.map((update, i) => [
            <MenuItem
              onClick={(e) => openNotificationModal(update)}
              disableRipple
              className={update?.read ? "" : classes.newNotification}
            >
              <Box display="flex" width="100%">
                <Box flex={1}>
                  <Typography variant="subtitle1">{update.title}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {moment(update.created_at).fromNow()}
                  </Typography>
                </Box>
                <Box flexGrow={0} width="min-content">
                  <IconButton
                    size="small"
                    style={{ marginRight: 8 }}
                    title="Mark as read"
                    onClick={onMarkRead(update.id)}
                  >
                    <Check fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    title="Dismiss"
                    onClick={onDismiss(update.id)}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </MenuItem>,
            i === liveUpdates.length - 1 ? null : <Divider />,
          ])}
          <Box display="flex" width="100%">
            <Button
              type="primary"
              size="small"
              className={classes.dismissBtn}
              onClick={onDismissAll}
            >
              Dismiss all
            </Button>

            <Button
              type="primary"
              size="small"
              className={classes.dismissBtn}
              onClick={onMarkReadAll}
            >
              Mark all as read
            </Button>
          </Box>
        </Menu>

        <Button
          classes={{
            label: classes.accountButtonLabel,
          }}
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={openAccount}
          color="inherit"
          startIcon={<AccountCircle />}
        >
          {getUser()?.name}
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={accountAnchor}
          keepMounted
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(accountAnchor)}
          onClose={closeAccount}
          classes={{
            list: classes.accountMenu,
          }}
        >
          <MenuItem onClick={handleLogout} disableRipple>
            <Logout /> Logout
          </MenuItem>
        </Menu>
      </Toolbar>

      <Dialog
        open={!!notification}
        onClose={onNotificationClose}
        aria-labelledby="add-language"
        maxWidth="sm"
        fullWidth
      >
        {notification && (
          <>
            <DialogTitle id="add-language">{notification.title}</DialogTitle>
            <DialogContent>
              <DialogContentText style={{ marginBottom: 16 }}>
                {notification.text.split("\n").map((line, i) => (
                  <p key={i}>
                    {line}
                    <br />
                  </p>
                ))}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onNotificationClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </AppBarMUI>
  );
}
