import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AppBar from "./AppBar";
import { useParams } from "react-router";
import { Grid, ListItemIcon } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { hasFeature, canAdmin, isSuperAdmin } from "../auth";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    width: drawerWidth - 48,
    textAlign: "center",
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    zIndex: "1 !important",
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxSizing: "border-box",
    "& a": {
      color: "inherit",
      textDecoration: "none",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  centerContainer: {
    margin: "0 auto",
  },
}));

const isSelected = (pattern) => {
  return window.location.pathname.endsWith("/" + pattern);
};

export const settingsList = {
  serveIT: [
    { url: "lms", title: "LMS/ILS", module: "LMS", smart: true },
    { url: "design", title: "Design", module: "Design" },
    { url: "payment", title: "Card Payment", module: "Payment" },
    hasFeature("council")
      ? {
          url: "council-payment",
          title: "Council Payments",
          module: "CouncilPayment",
        }
      : null,
    { url: "em-device", title: "EM Device", module: "EMDevice" },
    { url: "money", title: "Cash Payment", module: "Money" },
    { url: "timing", title: "Timing", module: "Timing" },
    { url: "sorting", title: "Sorting", module: "Sorting" },
    { url: "languages", title: "Languages", module: "Languages" },
    { url: "privacy", title: "Privacy", module: "Privacy" },
    { url: "receipts", title: "Receipts", module: "Receipts" },
    { url: "idle-screen", title: "Idle Screen", module: "IdleScreen" },
  ].filter((e) => e),
  holdIT: [
    { url: "general", title: "General", module: "General" },
    { url: "lms", title: "LMS/ILS", module: "LMS", smart: true },
    { url: "database", title: "Database", module: "Database" },
    { url: "design", title: "Design", module: "Design" },
    { url: "timing", title: "Timing", module: "Timing" },
    { url: "languages", title: "Languages", module: "Languages" },
    { url: "privacy", title: "Privacy", module: "Privacy", smart: true },
    { url: "receipts", title: "Receipts", module: "Receipts" },
  ],
  computeIT: [
    { url: "login", title: "Login", module: "Login", smart: true },
    {
      url: "borrower-source",
      title: "Borrower Source",
      module: "BorrowerSource",
      smart: true,
    },
    { url: "timing", title: "Timing", module: "Timing" },
    { url: "vending", title: "Vending", module: "Vending" },
    { url: "item-defects", title: "Item Defects", module: "ItemDefects" },
    { url: "accessibility", title: "Accessibility", module: "Accessibility" },
    // { url: "languages", title: "Languages", module: "Languages" },
    // { url: "privacy", title: "Privacy", module: "Privacy", smart: true },
    { url: "receipts", title: "Receipts", module: "Receipts" },
    {
      url: "borrower-identity",
      title: "Borrower Identity",
      module: "BorrowerIdentity",
      smart: true,
    },
    {
      url: "borrower-emails",
      title: "Borrower Emails",
      module: "BorrowerEmails",
      smart: true,
    },
    isSuperAdmin()
      ? {
          url: "admin-settings",
          title: "Admin Settings",
          module: "AdminSettings",
        }
      : null,
    canAdmin()
      ? {
          url: "admin-emails",
          title: "Admin Emails",
          module: "AdminEmails",
          smart: true,
        }
      : null,
  ].filter((e) => e),
  smart: [
    {
      url: "borrower-source",
      title: "Borrower Source",
      module: "BorrowerSource",
    },
    { url: "timing", title: "Timing", module: "Timing" },
    { url: "login", title: "Login", module: "Login" },
    { url: "languages", title: "Languages", module: "Languages" },
    { url: "privacy", title: "Privacy", module: "Privacy" },
    {
      url: "borrower-emails",
      title: "Borrower Emails",
      module: "BorrowerEmails",
    },
    {
      url: "borrower-identity",
      title: "Borrower Identity",
      module: "BorrowerIdentity",
    },
    canAdmin()
      ? {
          url: "admin-emails",
          title: "Admin Emails",
          module: "AdminEmails",
        }
      : null,
    { url: "receipts", title: "Receipts", module: "Receipts" },
  ].filter((e) => e),
};

export default function SettingsLayout({ children, smart, type }) {
  const classes = useStyles();
  const { siteId, kioskId } = useParams();

  const smartLink = smart ? "/smart" : "";

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar />

      <Drawer
        open={true}
        variant="permanent"
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <Link to={`/sites/${siteId}/kiosks/${kioskId}`}>
          <ListItem button>
            <ListItemIcon>
              <ArrowBack />
            </ListItemIcon>
            <ListItemText
              primary="Back"
              primaryTypographyProps={{
                variant: "body2",
              }}
            ></ListItemText>
          </ListItem>
        </Link>
        {settingsList[type]?.map((e) =>
          e.smart && smart ? null : (
            <Link
              key={e.url}
              to={`/sites/${siteId}/kiosks/${kioskId}${smartLink}/${type}/settings/${e.url}`}
            >
              <ListItem button selected={isSelected(e.url)}>
                <ListItemText
                  primary={e.title}
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                ></ListItemText>
              </ListItem>
            </Link>
          )
        )}
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={9} lg={6} className={classes.centerContainer}>
            {children}
          </Grid>
        </Grid>
      </main>
    </div>
  );
}
