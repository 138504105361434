import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import { Box, CircularProgress } from "@material-ui/core";
import { useQuery } from "react-query";
import { getKiosk } from "../../api";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import ShowSmart from "./ShowSmart";
import ShowRegular from "./ShowRegular";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: "100px auto !important",
  },
}));

export default function Show() {
  const classes = useStyles();
  const { siteId, kioskId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { data: kiosk, isLoading: kioskLoading } = useQuery(
    ["sites", siteId, "kiosks", kioskId],
    getKiosk(siteId, kioskId),
    {
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  return kioskLoading ? (
    <Layout>
      <Box display="flex">
        <CircularProgress className={classes.progress} />
      </Box>
    </Layout>
  ) : (
    kiosk &&
      (kiosk.type === 3 ? (
        <ShowSmart kiosk={kiosk} />
      ) : (
        <ShowRegular kiosk={kiosk} />
      ))
  );
}
