import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteUser, getUsers } from "../../api";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(4),
  },
  createButton: {
    marginLeft: "auto !important",
  },
  progress: {
    margin: "100px auto !important",
  },
}));

export default function Index() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { isLoading, data: users } = useQuery("users", getUsers(), {
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });

  const { mutate: doDeleteUser } = useMutation(deleteUser, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries("users");
    },
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });

  const confirm = useConfirm();
  const onDelete = (id) => async () => {
    try {
      await confirm({
        title: "Delete user",
        description:
          "Are you sure you want to delete this user, this action is permanent",
        confirmationText: "delete",
      });
      doDeleteUser(id);
    } catch (e) {}
  };

  return (
    <Layout>
      <Box display="flex">
        <Typography variant="h5">Users Management</Typography>

        <Link to="/users/create" className={classes.createButton}>
          <Button variant="contained" color="primary" startIcon={<AddIcon />}>
            Create
          </Button>
        </Link>
      </Box>

      {isLoading ? (
        <Box display="flex">
          <CircularProgress className={classes.progress} />
        </Box>
      ) : (
        <TableContainer component={Paper} className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Full name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">User type</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.length > 0 ? (
                users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell align="left">{user.email}</TableCell>
                    <TableCell align="left">{user.user_type_str}</TableCell>
                    <TableCell align="left">
                      <Link to={`/users/${user.id}/edit`}>
                        <IconButton aria-label="edit" title="Edit">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton
                        aria-label="delete"
                        title="Delete"
                        color="secondary"
                        onClick={onDelete(user.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No users found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Layout>
  );
}
