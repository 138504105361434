import { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ReplayIcon from "@material-ui/icons/Replay";
import { useMutation, useQueryClient } from "react-query";
import { createUser } from "../../api";
import { getFormPayload } from "../../utils";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import generator from "generate-password";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "auto 8px !important",
  },
  form: {
    marginTop: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(2) + " !important",
  },
}));

export default function Create() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword, setShowPassword]
  );

  const changePassword = useCallback(() => {
    const newPassword = generator.generate({
      length: 20,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
      excludeSimilarCharacters: true,
      strict: true,
    });

    setPassword(newPassword);
  }, [setPassword]);

  const passwordChanged = useCallback(
    (e) => setPassword(e.target.value),
    [setPassword]
  );

  const {
    mutate: doCreateUser,
    isLoading,
    error,
  } = useMutation(createUser, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries("users");
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const data = getFormPayload(e.currentTarget);
      doCreateUser(data);
    },
    [doCreateUser]
  );

  const validationErrors = error && error.errors;

  return (
    <Layout>
      <Box display="flex">
        <Link to="/users">
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </Link>
        <Typography variant="h5" className={classes.title}>
          Create new user
        </Typography>
      </Box>

      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          name="name"
          className={classes.input}
          label="Full name"
          required
          variant="outlined"
          fullWidth
          error={!!validationErrors?.name}
          helperText={validationErrors?.name}
        />
        <TextField
          name="email"
          className={classes.input}
          label="Email"
          required
          variant="outlined"
          fullWidth
          error={!!validationErrors?.email}
          helperText={validationErrors?.email}
        />

        <FormControl
          className={classes.input}
          variant="outlined"
          error={!!validationErrors?.password}
          fullWidth
          required
        >
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            name="password"
            id="password"
            type={showPassword ? "text" : "password"}
            label="Password"
            value={password}
            onChange={passwordChanged}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={togglePassword}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
                <IconButton onClick={changePassword}>
                  <ReplayIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{validationErrors?.password}</FormHelperText>
        </FormControl>

        <FormControl
          required
          className={classes.input}
          error={!!validationErrors?.user_type}
          fullWidth
        >
          <InputLabel id="user-type-label">User type</InputLabel>
          <Select
            name="user_type"
            labelId="user-type-label"
            id="user_type"
            defaultValue={100}
            label="User type"
          >
            <MenuItem value={400}>Admin</MenuItem>
            {/* <MenuItem value={300}>Staff control</MenuItem> */}
            <MenuItem value={100}>Viewer only</MenuItem>
          </Select>
          <FormHelperText>{validationErrors?.user_type}</FormHelperText>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disabled={isLoading}
        >
          Submit
        </Button>
      </form>
    </Layout>
  );
}
