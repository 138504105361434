import { makeStyles } from "@material-ui/core/styles";
import Layout from "../Layouts/AppLayout";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getNotificationSettings,
  getSites,
  updateNotificationSettings,
} from "../api";
import { useSnackbar } from "notistack";
import { Save } from "@material-ui/icons";
import { useCallback, useState } from "react";
import { getFormPayload } from "../utils";
import intersection from "lodash/intersection";
import KioskType, { typeNumToStr } from "../Components/KioskType";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(4),
  },
  createButton: {
    marginLeft: "auto !important",
  },
  progress: {
    margin: "100px auto !important",
  },
  centerContainer: {
    margin: "0 auto",
  },
  labelTypes: {
    transform: "scale(0.8)",
    transformOrigin: "left",
    display: "flex",
    marginTop: 8,

    "& img": {
      marginLeft: "0 !important",
    },

    "& img[alt*='computeIT']": {
      position: "relative",
      top: 3,
    },
  },
}));

const NotificationSchema = [
  {
    id: "kioskOffline",
    label: "Kiosk Offline",
    types: [0, 1],
  },
  {
    id: "receiptPaperLow",
    label: "Receipt paper low",
    types: [0, 1 /*,2*/],
  },
  {
    id: "coinLow",
    label: "Coin low",
    types: [0, 1],
  },
  {
    id: "kioskCnOffline",
    label: "Kiosk connection to ManageIT offline",
    types: [0, 1 /*,2*/],
    inputs: [
      {
        id: "interval",
        label: "Downtime (in minutes) before an offline alert is triggered",
        type: "number",
      },
    ],
  },
  //   {
  //     id: "itemDefect",
  //     label: "Item defect reported",
  //     types: [2],
  //   },
  //   {
  //     id: "borrowerSourceOffline",
  //     label: "Borrower data source offline",
  //     types: [2],
  //   },
  //   {
  //     id: "borrowerLoanOverdue",
  //     label: "Borrower loan overdue",
  //     types: [2],
  //   },
  //   {
  //     id: "itemStorageExpired",
  //     label: "Item storage expired",
  //     types: [2],
  //   },
  //   {
  //     id: "returnedDeviceNotConnected",
  //     label: "Returned device not connected",
  //     types: [2],
  //   },
  //   {
  //     id: "computeITAvailabilityUpdates",
  //     label: "computeIT availability updates",
  //     types: [2],
  //   },
];

export default function NotificationSettings() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [site, setSite] = useState("all");
  const [dirty, setDirty] = useState(false);

  const { data: sites } = useQuery(["sites"], getSites(), {
    onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
  });

  const { isLoading, data: notificationSettings } = useQuery(
    ["notification", site],
    getNotificationSettings(site),
    {
      onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
    }
  );
  const allNotifications = notificationSettings?.notifications ?? [];
  const kioskTypes = notificationSettings?.types ?? [0];

  const { mutate: doSave, isLoading: isUpdateLoading } = useMutation(
    updateNotificationSettings,
    {
      onSuccess: ({ message }) => {
        setDirty(false);
        queryClient.invalidateQueries(["notification", site]);
        enqueueSnackbar(message, { variant: "success" });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const settings = getFormPayload(e.target);
      doSave({ siteId: site, settings });
    },
    [doSave, site]
  );

  const updateField = (id) => (e, v) => {
    setTimeout(() => {
      document.getElementById(`settings[${id}][emails]`).value = v;
    }, 50);
    setDirty(true);
  };

  const onSiteChange = (e) => {
    if (dirty) {
      const res = window.confirm(
        "You have unsaved changes, do you want to discard them?"
      );
      if (res) {
        setSite(e.target.value);
        setDirty(false);
      }
    } else {
      setSite(e.target.value);
      setDirty(false);
    }
  };

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9} className={classes.centerContainer}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box display="flex">
              <Typography variant="h5">Notifications</Typography>
              <Button
                style={{ marginLeft: "auto" }}
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<Save />}
                disabled={isUpdateLoading}
              >
                Save
              </Button>
            </Box>

            <Box display="flex" mt={2} mb={2}>
              {sites && (
                <FormControl
                  sx={{ marginLeft: "auto", minWidth: 300 }}
                  size="small"
                >
                  <InputLabel>Selected site</InputLabel>
                  <Select
                    value={site}
                    onChange={onSiteChange}
                    label="Selected site"
                  >
                    <MenuItem value={`all`}>[Default] All sites</MenuItem>
                    {sites.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>

            {site === "all" ? (
              <Alert severity="info" style={{ marginBottom: 16 }}>
                This is the default configuration to set email alerts for all
                site locations, settings on individual sites will override this
                setting.
              </Alert>
            ) : (
              <Alert mt={4} severity="info" style={{ marginBottom: 16 }}>
                This configuration will set email alerts for this site location,
                it will override the default if set.
              </Alert>
            )}

            {isLoading ? (
              <Box display="flex">
                <CircularProgress className={classes.progress} />
              </Box>
            ) : (
              <TableContainer className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left">Enabled</TableCell>
                      <TableCell align="left">Emails</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {NotificationSchema.map(
                      ({ label, id, types, inputs }) =>
                        intersection(types, kioskTypes).length > 0 && (
                          <TableRow key={`${site}-${id}`}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ verticalAlign: "text-top", maxWidth: 100 }}
                            >
                              {label}
                              <div className={classes.labelTypes}>
                                {types.map((type) => (
                                  <div
                                    style={{
                                      marginRight: 8,
                                    }}
                                  >
                                    <KioskType type={typeNumToStr(type)} />
                                  </div>
                                ))}
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ verticalAlign: "text-top", width: 40 }}
                            >
                              <Checkbox
                                value="1"
                                color="primary"
                                name={`settings[${id}][isEnabled]`}
                                onChange={(e) => setDirty(true)}
                                defaultChecked={
                                  !!allNotifications?.[id]?.isEnabled
                                }
                              />
                            </TableCell>
                            <TableCell style={{ minWidth: 300 }} align="left">
                              <Autocomplete
                                onChange={updateField(id)}
                                defaultValue={
                                  allNotifications?.[id]?.emails?.split(",") ||
                                  []
                                }
                                multiple
                                freeSolo
                                options={[]}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ minWidth: 300 }}
                                    variant="standard"
                                    placeholder="Enter email and press enter..."
                                    onKeyDown={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                  />
                                )}
                              />
                              {inputs &&
                                inputs.map((input) => (
                                  <TextField
                                    inputProps={{ step: 1, min: 20, max: 60 }}
                                    variant="standard"
                                    type={input.type}
                                    label={input.label}
                                    sx={{ width: "100%", marginTop: 2 }}
                                    name={`settings[${id}][${input.id}]`}
                                    defaultValue={
                                      allNotifications?.[id]?.[input.id] ?? 20
                                    }
                                  />
                                ))}
                              <input
                                type="hidden"
                                name={`settings[${id}][emails]`}
                                id={`settings[${id}][emails]`}
                                value={allNotifications?.[id]?.emails ?? ""}
                              />
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </form>
        </Grid>
      </Grid>
    </Layout>
  );
}
