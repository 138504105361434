function toJSON(obj, keyStr, value) {
  const mainKey = keyStr.split("[")[0];
  // eslint-disable-next-line
  const matches = [...keyStr.matchAll(/\[([^\[\]]+)\]/g)].map((e) => e[1]);
  let [lastKey, ...keys] = matches.reverse();
  keys = [mainKey, ...keys.reverse()];
  let prev = obj;
  for (let k of keys) {
    if (!prev?.[k]) prev[k] = {};
    prev = prev[k];
  }
  prev[lastKey ?? ""] = value;
  return obj;
}

export function getFormPayload(form, event = null) {
  const formData = new FormData(form);
  if (event) {
    formData.append(event.submitter.name, event.submitter.value);
  }
  const payload = Array.from(formData.entries()).reduce((p, [k, v]) => {
    if (k.includes("[]")) {
      p[k.replace("[]", "")] = v
        .toString()
        .split(",")
        .filter((e) => e);
    } else if (k.includes("[")) {
      p = toJSON(p, k, v.toString());
    } else p[k ?? ""] = v.toString();
    return p;
  }, {});
  return payload;
}
