import { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../../Layouts/AppLayout";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import { createKiosk } from "../../api";
import { getFormPayload } from "../../utils";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "auto 8px !important",
  },
  form: {
    marginTop: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(2) + " !important",
  },
}));

export default function Create() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { siteId } = useParams();

  const {
    mutate: doCreateKiosk,
    isLoading,
    error,
  } = useMutation(createKiosk, {
    onSuccess: ({ message }) => {
      enqueueSnackbar(message, { variant: "success" });
      queryClient.invalidateQueries(["sites", siteId, "kiosks"]);
      queryClient.invalidateQueries(["sites", "tree"]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const kiosk = getFormPayload(e.currentTarget);
      doCreateKiosk({ siteId, kiosk });
    },
    [siteId, doCreateKiosk]
  );

  const validationErrors = error && error.errors;

  return (
    <Layout>
      <Box display="flex">
        <Link to={`/sites/${siteId}/kiosks`}>
          <IconButton>
            <ArrowBack />
          </IconButton>
        </Link>
        <Typography variant="h5" className={classes.title}>
          Create new kiosk
        </Typography>
      </Box>

      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          name="name"
          className={classes.input}
          label="Name"
          variant="outlined"
          fullWidth
          error={!!validationErrors?.name}
          helperText={validationErrors?.name}
        />

        <FormControl variant="outlined" fullWidth>
          <InputLabel id="type-label">Kiosk type</InputLabel>
          <Select
            name="type"
            className={classes.input}
            label="Kiosk type"
            labelId="type-label"
            variant="outlined"
            fullWidth
            error={!!validationErrors?.type}
            helperText={validationErrors?.type}
          >
            <MenuItem value={0}>serveIT</MenuItem>
            <MenuItem value={1}>holdIT</MenuItem>
            <MenuItem value={2}>computeIT</MenuItem>
            {/* <MenuItem value={3}>SMART</MenuItem> */}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disabled={isLoading}
        >
          Submit
        </Button>
      </form>
    </Layout>
  );
}
