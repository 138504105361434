import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { createRoot } from "react-dom/client";
import Dashboard from "./Containers/Dashboard";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import {
  hasTFAToken,
  IsLoggedIn,
  IsNotLoggedIn,
  isAdmin,
  hasFeature,
} from "./auth";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import { Provider, ErrorBoundary } from "@rollbar/react";

import Login from "./Containers/Auth/Login";
import TFA from "./Containers/Auth/TFA";
import Forgot from "./Containers/Auth/Forgot";
import Reset from "./Containers/Auth/Reset";
import WithToken from "./Containers/Auth/WithToken";
import LicenseExpired from "./Containers/Auth/LicenseExpired";

import HelpSupport from "./Containers/HelpSupport";

import Languages from "./Containers/Languages/Index";
import LanguageServeITEdit from "./Containers/Languages/ServeITEdit";
import LanguageHoldITEdit from "./Containers/Languages/HoldITEdit";

import NotificationSettings from "./Containers/NotificationSettings";
import EmailServerConfig from "./Containers/EmailServerConfig";

import UsersManagement from "./Containers/Users/Index";
import UsersCreate from "./Containers/Users/Create";
import UsersEdit from "./Containers/Users/Edit";

import LibrariesManagement from "./Containers/Sites/Index";
import LibrariesCreate from "./Containers/Sites/Create";
import LibrariesEdit from "./Containers/Sites/Edit";
import LibrariesAll from "./Containers/Sites/All";

import KiosksManagement from "./Containers/Kiosks/Index";
import KiosksShow from "./Containers/Kiosks/Show";
import KiosksCreate from "./Containers/Kiosks/Create";
import KiosksEdit from "./Containers/Kiosks/Edit";

import ServeITSettingsIndex from "./Containers/Settings/ServeIT/Index";
import HoldITSettingsIndex from "./Containers/Settings/HoldIT/Index";
import ComputeITSettingsIndex from "./Containers/Settings/ComputeIT/Index";

import ServeITThemeIndex from "./Containers/Theme/ServeIT/Index";
import HoldITThemeIndex from "./Containers/Theme/HoldIT/Index";
import ComputeITThemeIndex from "./Containers/Theme/ComputeIT/Index";

import CombinedStats from "./Containers/CombinedStats";
import Payments from "./Containers/Payments";

import SmartSettingsIndex from "./Containers/Settings/Smart/Index";
import SmartThemeIndex from "./Containers/Theme/Smart/Index";

import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import ZReport from "./Containers/ZReport";
import { getMe } from "./api";
import ActivityLog from "./Containers/ActivityLog";

const dtechTheme = createTheme({
  palette: {
    primary: {
      main: "#004a70",
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const subdomain = window.location.hostname.split(".")[0];
axios.defaults.headers.common["X-Location"] = subdomain;
axios.defaults.baseURL = process.env.REACT_APP_BACKEND;
axios.defaults.withCredentials = true;
axios.get("/sanctum/csrf-cookie");

setTimeout(async () => {
  try {
    const { user } = await getMe();
    if (user) window.localStorage.setItem("user", JSON.stringify(user));
  } catch (e) {}
}, 5000);

const container = document.getElementById("root");
const root = createRoot(container);

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN,
  environment: process.env.REACT_APP_ENV,
};

root.render(
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <ThemeProvider theme={dtechTheme}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={3}>
            <ConfirmProvider>
              <Router>
                <GuardProvider>
                  <Switch>
                    <GuardedRoute
                      path="/token/:token"
                      exact
                      component={WithToken}
                    />
                    <GuardedRoute
                      path="/license-expired"
                      exact
                      component={LicenseExpired}
                    />
                    <GuardedRoute
                      path="/login"
                      exact
                      component={Login}
                      guards={[IsNotLoggedIn]}
                    />
                    <GuardedRoute
                      path="/forgot"
                      exact
                      component={Forgot}
                      guards={[IsNotLoggedIn]}
                    />
                    <GuardedRoute
                      path="/reset-password"
                      exact
                      component={Reset}
                      guards={[IsNotLoggedIn]}
                    />
                    <GuardedRoute
                      path="/tfa"
                      exact
                      component={TFA}
                      guards={[IsNotLoggedIn, hasTFAToken]}
                    />
                    <GuardedRoute
                      path="/"
                      exact
                      component={Dashboard}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn]}
                    />
                    <GuardedRoute
                      path="/help"
                      exact
                      component={HelpSupport}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn]}
                    />
                    <GuardedRoute
                      path="/languages"
                      exact
                      component={Languages}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/languages/:id/serveIT/edit"
                      exact
                      component={LanguageServeITEdit}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/languages/:id/holdIT/edit"
                      exact
                      component={LanguageHoldITEdit}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/notifications"
                      exact
                      component={NotificationSettings}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/email-server"
                      exact
                      component={EmailServerConfig}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/activity-log"
                      exact
                      component={ActivityLog}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/users"
                      exact
                      component={UsersManagement}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/users/create"
                      exact
                      component={UsersCreate}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/users/:id/edit"
                      exact
                      component={UsersEdit}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites"
                      exact
                      component={LibrariesManagement}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/create"
                      exact
                      component={LibrariesCreate}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:id/edit"
                      exact
                      component={LibrariesEdit}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/kiosks"
                      exact
                      component={LibrariesAll}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks"
                      exact
                      component={KiosksManagement}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/create"
                      exact
                      component={KiosksCreate}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId"
                      exact
                      component={KiosksShow}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/edit"
                      exact
                      component={KiosksEdit}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/serveIT/settings/:tab?"
                      exact
                      render={() => <ServeITSettingsIndex />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/holdIT/settings/:tab?"
                      exact
                      render={() => <HoldITSettingsIndex />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/computeIT/settings/:tab?"
                      exact
                      render={() => <ComputeITSettingsIndex />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    {/* Themes */}
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/serveIT/theme/:tab?/:subTab?"
                      exact
                      render={() => <ServeITThemeIndex />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/holdIT/theme/:tab?/:subTab?"
                      exact
                      render={() => <HoldITThemeIndex />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/computeIT/theme/:tab?/:subTab?"
                      exact
                      render={() => <ComputeITThemeIndex />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />

                    {/* Smart */}
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/settings/:tab?"
                      exact
                      render={() => <SmartSettingsIndex />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/serveIT/settings/:tab?"
                      exact
                      render={() => (
                        <ServeITSettingsIndex smartType="serveIT" />
                      )}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/holdIT/settings/:tab?"
                      exact
                      render={() => <HoldITSettingsIndex smartType="holdIT" />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/computeIT/settings/:tab?"
                      exact
                      render={() => (
                        <ComputeITSettingsIndex smartType="computeIT" />
                      )}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />

                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/theme/:tab?"
                      exact
                      render={() => <SmartThemeIndex />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/theme/:tab?/:subTab?"
                      exact
                      render={() => <SmartThemeIndex />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />

                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/serveIT/theme/:tab?"
                      exact
                      render={() => <ServeITThemeIndex smartType="serveIT" />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/serveIT/theme/:tab?/:subTab?"
                      exact
                      render={() => <ServeITThemeIndex smartType="serveIT" />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/holdIT/theme/:tab?"
                      exact
                      render={() => <HoldITThemeIndex smartType="holdIT" />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/holdIT/theme/:tab?/:subTab?"
                      exact
                      render={() => <HoldITThemeIndex smartType="holdIT" />}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    {/* <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/computeIT/theme/:tab?"
                      exact
                      render={() => (
                        <ComputeITThemeIndex smartType="computeIT" />
                      )}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    />
                    <GuardedRoute
                      path="/sites/:siteId/kiosks/:kioskId/smart/computeIT/theme/:tab?/:subTab?"
                      exact
                      render={() => (
                        <ComputeITThemeIndex smartType="computeIT" />
                      )}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn, isAdmin]}
                    /> */}

                    <GuardedRoute
                      path="/combined-stats"
                      exact
                      component={CombinedStats}
                      meta={{ auth: true }}
                      guards={[IsLoggedIn]}
                    />
                    <GuardedRoute
                      path="/payments"
                      exact
                      meta={{ auth: true }}
                      guards={[IsLoggedIn]}
                    >
                      <Payments type="payment" title="Library payments" />
                    </GuardedRoute>
                    {hasFeature("council") && (
                      <GuardedRoute
                        path="/council-payments"
                        exact
                        meta={{ auth: true }}
                        guards={[IsLoggedIn]}
                      >
                        <Payments type="council" title="Council payments" />
                      </GuardedRoute>
                    )}
                    {hasFeature("zreport") && (
                      <GuardedRoute
                        path="/z-report"
                        exact
                        component={ZReport}
                        meta={{ auth: true }}
                        guards={[IsLoggedIn]}
                      />
                    )}
                    <GuardedRoute>
                      <Redirect to="/" />
                    </GuardedRoute>
                  </Switch>
                </GuardProvider>
              </Router>
            </ConfirmProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
