import { useMemo, useState } from "react";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Paper,
  Button,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { ResponsiveBar } from "@nivo/bar";
import {
  downloadCombinedCountsByDate,
  downloadCombinedCountsByKiosk,
  getCombinedCounts,
} from "../api";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import Layout from "../Layouts/AppLayout";
import DatePicker from "../Components/DatePicker";
import { last7DaysRange } from "../Components/DatePicker";
import { saveAs } from "file-saver";
import { KioskSelector } from "../Components/KioskSelector";

const useStyles = makeStyles((theme) => ({
  treeItem: {
    backgroundColor: "transparent !important",
    height: 40,
  },
  progress: {
    margin: "100px auto !important",
  },
}));

export default function CombinedStats() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [counts, setCounts] = useState({});
  const [dateRange, setDateRange] = useState(last7DaysRange);
  const [startDate, endDate] = dateRange;

  const [selectedIds, setSelectedIds] = useState([]);

  const { isLoading: countsLoading } = useQuery(
    ["combined-stats", selectedIds, startDate, endDate],
    getCombinedCounts(selectedIds, startDate, endDate),
    {
      enabled: selectedIds.length > 0,
      onSuccess: (counts) => setCounts(counts),
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const data = useMemo(() => {
    return counts
      ? Object.entries(counts).map((e) => ({
          key: e[0],
          value: e[1] ? parseInt(e[1]) : 0,
        }))
      : [];
  }, [counts]);

  const { mutate: doDownloadDate } = useMutation(downloadCombinedCountsByDate, {
    onSuccess: ({ data }) => {
      saveAs(data, "all-counts-date.csv");
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const onDownloadDate = () => {
    doDownloadDate({ ids: selectedIds, startDate, endDate });
  };

  const { mutate: doDownloadKiosk } = useMutation(
    downloadCombinedCountsByKiosk,
    {
      onSuccess: ({ data }) => {
        saveAs(data, "all-counts-kiosk.csv");
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const onDownloadKiosk = () => {
    doDownloadKiosk({ ids: selectedIds, startDate, endDate });
  };

  return (
    <Layout>
      <Typography variant="h5">Combined Statistics</Typography>

      <Grid container spacing={3} marginTop={4}>
        <Grid item xs={12} md={3}>
          <KioskSelector onChange={(ids) => setSelectedIds(ids)} />
        </Grid>

        <Grid item xs={12} md={9}>
          <DatePicker
            onChange={setDateRange}
            element={
              <>
                <Button
                  startIcon={<GetApp />}
                  onClick={onDownloadDate}
                  style={{ marginLeft: "auto" }}
                >
                  Export By Date
                </Button>
                <Button
                  startIcon={<GetApp />}
                  onClick={onDownloadKiosk}
                  style={{ marginLeft: 12 }}
                >
                  Export By Kiosk
                </Button>
              </>
            }
          />

          {countsLoading ? (
            <Box display="flex">
              <CircularProgress className={classes.progress} />
            </Box>
          ) : (
            <Paper>
              <div style={{ height: "480px", width: "99%" }}>
                <ResponsiveBar
                  labelSkipHeight={16}
                  colorBy="indexValue"
                  margin={{ top: 60, right: 80, bottom: 120, left: 80 }}
                  axisBottom={{
                    tickRotation: -45,
                    format: (value) => {
                      return value
                        .split("_")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ");
                    },
                  }}
                  axisLeft={false}
                  isInteractive={false}
                  data={data}
                  padding={0.3}
                  indexBy="key"
                  keys={["value"]}
                />
              </div>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}
